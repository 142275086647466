import { createRouter, createWebHistory } from 'vue-router'
import EmployeeView from '@/views/employee-view/EmployeeDashView.vue'
import LoginView from '@/views/LoginView'
import AdminDashboard from '@/views/admin-view/AdminDashboardView.vue'
import Admin from '@/router/admin'
import Employee from '@/router/employee'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      role: '',
      requiresAuth: false
    }
  },
  {
    path: '/admin',
    component: AdminDashboard,
    children: Admin,
    meta: {
      role: 'admin',
      requiresAuth: true
    }
  },
  {
    path: '/employee',
    component: EmployeeView,
    children: Employee,
    meta: {
      role: 'employee',
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = sessionStorage.getItem('token') !== null
  const role = sessionStorage.role

  // Update the title based on the role
  if (role === 'admin') {
    document.title = 'Admin Dashboard'
  } else if (role === 'employee') {
    document.title = 'Employee Dashboard'
  } else {
    document.title = 'Login'
  }

  // Define routes that require authentication
  const authRequiredRoutes = ['/admin', '/employee']

  if (authRequiredRoutes.includes(to.path) && !isAuthenticated) {
    // Redirect to the login page if not authenticated
    next('/')
  } else if (to.path === '/' && isAuthenticated) {
    // Redirect to the appropriate dashboard based on the role
    if (role === 'admin') {
      // need to implement geolocation
      next('/admin')
    } else if (role === 'employee') {
      next('/employee')
    } else {
      // Handle other roles or unexpected cases
      next('/')
    }
  } else if (to.path === '/admin' && role !== 'admin') {
    // Redirect employee to their dashboard if they try to access admin
    next('/employee')
  } else if (to.path === '/employee' && role !== 'employee') {
    // Redirect admin to their dashboard if they try to access employee
    next('/admin')
  } else {
    // Allow access to the requested route
    next()
  }
})

export default router
