<template>
 <div class="sidebar-div">
    <div class="logo-exit-div">
        <div class="wagyuniku-img">
            <a href="/employee/"><img class="sideLogo" src="../../assets/received_202374629205091.webp" alt="wagyuniku"/></a>
          </div>
          <div class="exit">
            <i class="exitBtn"><font-awesome-icon icon="xmark" @click="exitSidebar"/></i>
          </div>
    </div>
    <div class="tabs-list-div">
        <ul class="tabs-unsortedList">
          <a class="tabs-li" href="/employee/attendance-module">
              <font-awesome-icon icon="building-user" />
              <p>Attendance Module</p>
          </a>
          <a class="tabs-li" href="/employee/schedule-module">
            <font-awesome-icon icon="calendar" />
            <p>Schedule Module</p>
          </a>
          <a class="tabs-li" href="/employee/payroll-module">
            <font-awesome-icon icon="money-bill-wave" />
            <p>Payroll Module</p>
          </a>
        </ul>
    </div>
 </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'AdminSidebar',
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    attendanceDropBtn () {
      this.isActive = !this.isActive
    },
    exitSidebar () {
      const sidebarDiv = document.querySelector('.sidebar-div')
      sidebarDiv.classList.remove('active')
    }
  }
})
</script>
