<template>
    <div class="user-settings">
        <header>
            <div class="back-div">
                <div class="back-btn-div" @click="backClicked">
                  <font-awesome-icon icon="arrow-left"/>
                  <p>Back</p>
                </div>
                <h1>Edit user profile</h1>
            </div>
        </header>
        <main>
            <div class="user-detials">
              <ChangeDetail
                v-show="detailModal"
                @success="successUpdateDetails"
                @saveChanges="saveChange"
                @exitModal="closeModal"/>
                <ChangePassword
                  v-show="passwordModal"
                  @success="successChangePass"
                  @exitModal="closeModal"/>
                <SuccessModal
                  v-show="successModal"
                  :message="message"
                  @exitModal="closeModal"/>
                  <ErrorModal
                    v-show="errorModal"
                    @exitModal="closeModal"
                  />
                <form class="user-settings-form">
                    <button class="editBtn" @click="editBtn">Edit Details</button>
                    <div id="fnNm-div">
                      <label for="firstName">First Name</label>
                      <input
                          name="firstName"
                          id="firstName"
                          v-model="computedUserData.firstName"
                          type="text"
                          disabled/>
                    </div>
                    <div id="mdlNm-div">
                      <label for="middleName">Middle Name</label>
                      <input
                          name="middleName"
                          id="middleName"
                          v-model="computedUserData.middleName"
                          type="text"
                          disabled/>
                    </div>
                    <div id="lstNm-div">
                      <label for="lastName">Last Name</label>
                      <input
                          name="lastName"
                          id="lastName"
                          v-model="computedUserData.lastName"
                          type="text"
                          disabled/>
                    </div>
                    <div id="email-copy-div">
                      <label for="email">Email</label>
                      <div id="email-copy">
                        <input
                        name="email"
                        id="email"
                        v-model="computedUserData.personalEmail"
                        type="text"
                        disabled
                          />
                          <font-awesome-icon class="copy-email" icon="copy" @click="copyBtn"/>
                      </div>
                    </div>
                    <div id="phNumber-copy-div">
                      <label for="phNumber">Phone Number</label>
                      <div id="phNumber-copy">
                        <input
                          name="phNumber"
                          id="phNumber"
                          v-model="computedUserData.PhoneNumber"
                          type="text"
                          disabled
                            />
                        <font-awesome-icon class="copy-num" icon="copy" @click="copyBtn"/>
                      </div>
                    </div>
                </form>
            </div>
            <div class="profile-password">
                <img class="profile-img" :src="avatar" alt="User Avatar"/>
              <form class="profile-btn-form">
                <label for="uploadBtn" id="uploadBtnLbl">
                  <font-awesome-icon icon="camera"/>
                  Change profile image
                </label>
                <input
                  name="uploadBtn"
                  type="file"
                  id="uploadBtn"
                  @change="handleImg"
                  hidden
                />
              </form>
              <button class="change-password" @click="changePassBtn">Change Password</button>
            </div>
          </main>
        <footer>
          <button class="undoBtn" type="reset" v-if="changes || photoChange" @click="undoBtn">UNDO</button>
          <button class="submitBtn" type="submit" v-if="changes || photoChange" @click="submintBtn">SAVE CHANGES</button>
        </footer>
    </div>
</template>

<script>
import ChangeDetail from '@/modal/ChangeDetail.vue'
import ChangePassword from '@/modal/ChangePassword.vue'
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'

import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import axios from '@/api/axios'
import { mapState, mapActions, mapMutations } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'userPage',
  components: {
    ChangeDetail,
    ChangePassword,
    SuccessModal,
    ErrorModal
  },
  data () {
    return {
      changes: false,
      photoChange: false,
      detailModal: false,
      passwordModal: false,
      successModal: false,
      errorModal: false,
      message: '',
      authDetails: {
        PhotoUrl: ''
      },
      newImg: null,
      userDetails: {
        firstName: '',
        middleName: '',
        lastName: '',
        personalEmail: '',
        PhoneNumber: ''
      },
      imageURL: null,
      originalUserData: { ...this.userData }, // Store the original user data here
      changesSaved: false // A flag to track if changes are saved
    }
  },
  watch: {
    userData: {
      handler (newUserData) {
      // Update originalUserData whenever userData changes
        this.originalUserData = { ...newUserData }
      },
      immediate: true // Trigger the handler immediately when the component is created
    },
    authData: {
      handler (newData) {
        this.authDetails = { ...newData }
      },
      immediate: true
    }
  },
  computed: {
    computedUserData () {
      // Use a computed property to determine which data to display
      return this.changes ? this.changeDetails : this.userData
    },
    ...mapState(['authData']),
    ...mapState(['userData']),
    ...mapState(['changeDetails']),
    avatar () {
      // Map the avatar property from your store's userData to a local computed property
      this.imageURL = this.authData.photoUrl
      return this.photoChange ? this.authDetails.PhotoUrl : this.imageURL
    }
  },
  methods: {
    backClicked () {
      // Gets the recent URL history and if triggerd the user will go back to the previus page
      this.$router.back(-1)
    },
    editBtn (e) {
      e.preventDefault()
      this.detailModal = !this.detailModal
    },
    saveChange () {
      this.changes = !this.changes
    },
    successChangePass () {
      this.message = 'Password Change Successful!'
      this.successModal = true
    },
    successUpdateDetails () {
      this.message = 'Details Updated Successful!'
      this.successModal = true
    },
    closeModal () {
      this.detailModal = false
      this.passwordModal = false
      this.successModal = false
      this.errorModal = false
    },
    copyBtn (e) {
      const target = e.target.classList
      if (target.contains('copy-email')) {
        // Get the input element by its ID
        const inputElement = document.querySelector('#email')
        // Select the text inside the input element
        inputElement.select()

        // Copy the selected text to the clipboard
        document.execCommand('copy')
      } else if (target.contains('copy-num')) {
        // Get the input element by its ID
        const inputElement = document.querySelector('#phNumber')
        // Select the text inside the input element
        inputElement.select()

        // Copy the selected text to the clipboard
        document.execCommand('copy')
      }
    },
    changePassBtn () {
      this.passwordModal = true
    },
    async handleImg (e) {
      e.preventDefault()
      const file = e.target.files[0]
      // Use FileReader to read the image and set userDetails.avatar
      const reader = new FileReader()
      reader.onload = (event) => {
        this.authDetails.PhotoUrl = event.target.result

        this.photoChange = !this.photoChange
      }

      reader.readAsDataURL(file)
    },
    async submintBtn () {
      const token = sessionStorage.getItem('token')
      const dataString = sessionStorage.getItem('auth-data')
      const dataObject = JSON.parse(dataString)
      const id = dataObject.Uid
      const formData = new FormData()
      if (this.authDetails.PhotoUrl && !this.authDetails.PhotoUrl.includes('https')) {
        formData.append('id', id)
        formData.append('photoUrl', this.authDetails.PhotoUrl)
        formData.append('lastName', this.userData.lastName)
      }
      if (this.changeDetails.lastName) {
        formData.append('id', id)
        formData.append('personalEmail', this.changeDetails.personalEmail)
        formData.append('firstName', this.changeDetails.firstName)
        formData.append('middleName', this.changeDetails.middleName)
        formData.append('lastName', this.changeDetails.lastName)
        formData.append('phoneNumber', this.changeDetails.PhoneNumber)
      }
      try {
        const updateProfile = await axios.put('/update/admin',
          formData, {
            headers: {
              Authorization: `${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (updateProfile.status === 200) {
          const data = updateProfile.data
          console.log(data[0].Item1)

          if (data[0].Item1 !== null && data[0].Item1.includes('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images')) {
            const modifiedURL = data[0].Item1.replace('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images', '/images')

            const storage = getStorage()
            const forestRef = ref(storage, modifiedURL)

            try {
              const url = await getDownloadURL(forestRef)
              this.newImg = url // Set the URL to avatarURL if successful
            } catch (error) {
              console.error('Error fetching download URL:', error)
              this.newImgL = null // Set avatarURL to null in case of error
            }
            const authDetails = {
              photoUrl: this.newImg,
              Uid: data[0].Item2,
              email: data[0].Item3
            }
            this.$store.commit('setAuthData', {
              photoUrl: this.newImg,
              Uid: id,
              email: dataObject.email
            })
            console.log(authDetails)
            this.updateAuth(authDetails)
            this.setChangesSaved(true)
            this.photoChange = !this.photoChange
          }

          if (data[0].Item1 !== null && !data[0].Item1.includes('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images')) {
            const newUserDetail = {
              personalEmail: data[0].item1,
              firstName: data[0].Item2,
              middleName: data[0].Item3,
              lastName: data[0].Item4,
              PhoneNumber: data[0].Item5
            }
            this.updateUserData(newUserDetail)
            this.changes = !this.changes
          }
        }
      } catch (e) {
        console.log(e)
      }
      if (this.userDetails) {
        this.originalUserData = { ...this.userDetails }
      }
      this.setChangesSaved(true)
    },
    undoBtn () {
      this.changes = !this.changes
      // Restore the original user data to userDetails
      this.userDetails = { ...this.originalUserData }

      // Update Vuex store with the original user data
      this.updateUserData(this.originalUserData)

      // Set changesSaved flag to false
      this.setChangesSaved(false)
    },
    // Use Vuex mapActions to dispatch an action to update the userData
    ...mapActions(['updateUserData']),
    ...mapActions(['updateAuth']),
    // Use Vuex mapMutations to commit a mutation to set the changesSaved flag
    ...mapMutations(['setChangesSaved'])
  }
})
</script>
