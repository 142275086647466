<template>
  <div class="adminDashboard-div">
    <AdminSideBar v-if="sidebar" />
    <Navbar />
    <router-view />
  </div>
</template>

<script lang="js">
import Navbar from '@/components/Navbar.vue'
import AdminSideBar from '@/components/admin-components/AdminSideBar.vue'

export default (await import('vue')).defineComponent({
  name: 'dashboardView',
  data () {
    return {
      sidebar: true
    }
  },
  components: {
    Navbar,
    AdminSideBar
  }
})
</script>
