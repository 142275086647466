<template>
    <div class="create-schedule-modal">
        <div id="modal-body">
            <header>
                <p id="change-detail-title">Create Schedule</p>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
            </header>
            <main>
                <div class="title-sched-time-date-container">
                    <div class="title-desc-container">
                        <div id="title-div">
                            <label for="sched-title" id="sched-title-lbl">
                                Title:
                            </label>
                            <input
                                type="text"
                                id="sched-title-input"
                                v-model="createData.title"
                                name="sched-title"
                                placeholder="schedule title..."
                                autocomplete="off"
                            />
                        </div>
                        <div id="desc-div">
                            <label
                                for="schedule-description"
                                id="sched-desc-lbl">
                                Schedule Description:
                            </label>
                            <input
                                type="text"
                                name="schedule-description"
                                id="sched-desc-input"
                                v-model="createData.description"
                                placeholder="schedule description..."
                                autocomplete="off"
                            />
                        </div>
                    </div>
                    <div class="time-container">
                        <div id="start-time-div">
                            <label
                                for="start-time"
                                id="start-time-lbl">
                                Starting time:
                            </label>
                            <input
                                type="time"
                                name="start-time"
                                v-model="createData.startTime"
                                id="start-time-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="start-date-div">
                            <label
                                for="start-date"
                                id="start-date-lbl">
                                Starting date:
                            </label>
                            <input
                                type="date"
                                name="start-date"
                                v-model="createData.startDate"
                                id="start-date-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="end-time-div">
                            <label
                                for="end-time"
                                id="end-time-lbl">
                                End Time:
                            </label>
                            <input
                                type="time"
                                name="end-time"
                                v-model="createData.endTime"
                                id="end-time-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="end-date-div">
                            <label
                                for="end-date"
                                id="end-date-lbl">
                                End Date:
                            </label>
                            <input
                                type="date"
                                name="end-date"
                                id="end-date-input"
                                v-model="createData.endDate"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div class="selection-container">
                    <div class="employee-container">
                        <div>
                            <h2>
                                Assign Employee:
                            </h2>
                        </div>
                        <div id="search-employee-container">
                            <label class="search-lbl">Search: </label>
                            <input
                                type="search"
                                v-model="employee"
                                placeholder="employee..."
                                autocomplete="off"
                            />
                            <font-awesome-icon icon="magnifying-glass" class="searchBtn"/>
                        </div>
                        <div class="list-container">
                            <ul id="list">
                                <!-- Display selected employees -->
                                <li class="employee-name-list" v-for="employee in createData.selectedEmployees" :key="employee.Id">
                                <input
                                    type="checkbox"
                                    :value="employee"
                                    @change="toggleSelected(employee)"
                                    class="employee-checkbox"
                                    :id="'employee-checkbox-' + employee.Id"
                                    checked
                                >
                                <label :for="'employee-checkbox-' + employee.id">{{ employee.FirstName }}</label>
                                </li>
                                <!-- Display search results -->
                                <li class="employee-name-list" v-for="data in result" :key="data.Id">
                                <input
                                    type="checkbox"
                                    :value="data"
                                    @change="toggleSelected(data)"
                                    class="employee-checkbox"
                                    :id="'employee-checkbox-' + data.Id"
                                >
                                <label :for="'employee-checkbox-' + data.Id">{{ data.FirstName }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="recur-container">
                        <div id="recur-selection-container">
                            <div id="selection-title">
                                <h2>Type of Schedule:</h2>
                            </div>
                            <div id="selection-radio">
                                <input
                                type="radio"
                                id="repeating-rd"
                                name="repeat"
                                value="repeating"
                                v-model="isRepeatingVal"
                                >
                                <label for="repeat" id="repeating-lbl"> Repeating </label>
                                <input
                                    type="radio"
                                    id="not-repeating-rd"
                                    name="not-repeat"
                                    value="not"
                                    v-model="isRepeatingVal"
                                >
                                <label for="not-repeat" id="repeating-lbl"> Not repeating</label>
                            </div>
                        </div>
                        <div id="days-container" v-if="createData.isRepeating">
                            <div>
                                <h2>Select Days:</h2>
                            </div>
                            <div id="sunday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('SUN')"
                                id="is-sunday"
                                value="0"
                                v-model="day"
                                name="sunday"
                                >
                                <label for="sunday"> Sunday</label>
                            </div>
                            <div id="monday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('M')"
                                id="is-monday"
                                name="monday"
                                >
                                <label for="monday"> Monday</label>
                            </div>
                            <div id="tuesday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('T')"
                                id="is-tuesday"
                                name="tuesday"
                                >
                                <label for="tuesday"> Tuesday</label>
                            </div>
                            <div id="wednesdat-div">
                                <input
                                type="checkbox"
                                @change="addToDays('W')"
                                id="is-wednesday"
                                name="wednesday"
                                >
                                <label for="wednesday"> Wednesday</label>
                            </div>
                            <div id="thursday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('TH')"
                                id="is-thursday"
                                name="thursday"
                                >
                                <label for="thursday"> Thursday</label>
                            </div>
                            <div id="friday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('F')"
                                id="is-friday"
                                name="friday"
                                >
                                <label for="friday"> Friday</label>
                            </div>
                            <div id="saturday-div">
                                <input
                                type="checkbox"
                                @change="addToDays('SAT')"
                                id="is-saturday"
                                name="saturday"
                                >
                                <label for="saturday"> Saturday</label>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <button class="createBtn" @click="createBtn" :disabled="!createData.title">Create</button>
                <button class="cancelBtn" @click="cancelBtn">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import ErrorModal from './ErrorModal.vue'

export default (await import('vue')).defineComponent({
  name: 'create-schedule',
  props: {
    message: String
  },
  data () {
    return {
      employee: '',
      isRepeatingVal: '',
      day: '',
      createData: {
        title: '',
        description: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        selectedEmployees: [],
        isRepeating: null,
        days: []
      },
      result: [],
      addEmployee: ''
    }
  },
  components: {
    ErrorModal
  },
  watch: {
    createData: {
      deep: true,
      handler (newVal, oldVal) {
        if (newVal.startTime) {
          // Parse the start time to extract the hour part
          const startTimeHour = parseInt(newVal.startTime.split(':')[0], 10)

          // Calculate the end time by adding 9 hours to the start time
          const computedEndHour = (startTimeHour + 9) % 24
          const computedEndMinute = newVal.startTime.split(':')[1]

          // Format the computed end time
          newVal.endTime = `${computedEndHour.toString().padStart(2, '0')}:${computedEndMinute}`
        }
      }
    },
    isRepeatingVal (newVal, oldVal) {
      if (newVal.includes('not')) {
        this.createData.isRepeating = false
      } else if (oldVal.includes('not') && !newVal.includes('not')) {
        this.createData.isRepeating = true
      } else {
        this.createData.isRepeating = true
      }
    },
    employee (value) {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }
      if (value) {
        setTimeout(async () => {
          const list = await axios.get(`/employee/list?search=${value}`, config)
          this.result = list.data
        }, 2000)
      } else {
        this.result = []
      }
    }
  },
  methods: {
    toggleSelected (employee) {
      // Check if the employee is already selected
      const index = this.createData.selectedEmployees.findIndex((e) => e.Id === employee.Id)

      if (index === -1) {
        // If not selected, add the employee to the array
        this.createData.selectedEmployees.push(employee)
      } else {
        // If already selected, remove the employee from the array
        this.createData.selectedEmployees.splice(index, 1)
      }
    },
    addToDays (days) {
      const index = this.createData.days.indexOf(days)

      if (index) {
        this.createData.days.push(days)
      } else {
        this.createData.days.splice(index, 1)
      }
    },
    exitModal () {
      this.$emit('exitSched')
    },
    async createBtn () {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }
      try {
        const requestData = {
          Title: this.createData.title,
          Description: this.createData.description,
          StartTime: this.createData.startTime,
          StartDate: this.createData.startDate,
          EndTime: this.createData.endTime,
          EndDate: this.createData.endDate,
          Repeating: this.createData.isRepeating.toString(),
          selectedEmployees: this.createData.selectedEmployees,
          Days: this.createData.days,
          Schedules: [] // Ensure all properties are included
        }
        console.log(requestData)
        const newSced = await axios.post('/employee/schedule', requestData, config)

        if (newSced.status === 200) {
          // handles the new data
          this.$emit('handleMessage', 'new Schedule Created')
          this.$emit('success')
        } else {
          // handles error
          this.$emit('handleMessage', 'failed to create Schedule')
          this.$emit('error')
        }
      } catch (e) {
        // handles deep error
        console.log(e)
        this.$emit('handleMessage', 'Error occur')
        this.$emit('error')
      }
    },
    cancelBtn () {
      this.exitModal()
    }
  }
})
</script>
