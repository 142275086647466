<template>
    <div class="employeeDashboard-div">
        <EmployeeSidebar v-if="sidebar" />
        <Navbar />
        <router-view />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import EmployeeSidebar from '@/components/employee-components/EmployeeSidebar.vue'

export default (await import('vue')).defineComponent({
  name: 'empDashView',
  data () {
    return {
      sidebar: true
    }
  },
  components: {
    Navbar,
    EmployeeSidebar
  }
})
</script>
