<template>
 <div class="schedule-modal">
    <div id="modal-body">
      <header>
        <p id="change-detail-title">Handle Schedule</p>
        <font-awesome-icon class="exit-msg" icon="xmark" @click="exitSched"/>
    </header>
    <main>
      <div class="title-sched-time-date-container">
                    <div class="title-desc-container">
                        <div id="title-div">
                            <label for="sched-title" id="sched-title-lbl">
                                Title:
                            </label>
                            <input
                                type="text"
                                id="sched-title-input"
                                v-model="createData.title"
                                name="sched-title"
                                placeholder="schedule title..."
                                autocomplete="off"
                            />
                        </div>
                        <div id="desc-div">
                            <label
                                for="schedule-description"
                                id="sched-desc-lbl">
                                Schedule Description:
                            </label>
                            <input
                                type="text"
                                name="schedule-description"
                                id="sched-desc-input"
                                v-model="createData.description"
                                placeholder="schedule description..."
                                autocomplete="off"
                            />
                        </div>
                    </div>
                    <div class="time-container">
                        <div id="start-time-div">
                            <label
                                for="start-time"
                                id="start-time-lbl">
                                Starting time:
                            </label>
                            <input
                                type="time"
                                name="start-time"
                                v-model="createData.startTime"
                                id="start-time-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="start-date-div">
                            <label
                                for="start-date"
                                id="start-date-lbl">
                                Starting date:
                            </label>
                            <input
                                type="date"
                                name="start-date"
                                v-model="createData.startDate"
                                id="start-date-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="end-time-div">
                            <label
                                for="end-time"
                                id="end-time-lbl">
                                End Time:
                            </label>
                            <input
                                type="time"
                                name="end-time"
                                v-model="createData.endTime"
                                id="end-time-input"
                                autocomplete="off"
                            />
                        </div>
                        <div id="end-date-div">
                            <label
                                for="end-date"
                                id="end-date-lbl">
                                End Date:
                            </label>
                            <input
                                type="date"
                                name="end-date"
                                id="end-date-input"
                                v-model="createData.endDate"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div class="selection-container">
                    <div class="employee-container">
                        <div>
                            <h2>
                                Assign Employee:
                            </h2>
                        </div>
                        <div id="search-employee-container">
                            <label class="search-lbl">Search: </label>
                            <input
                                type="search"
                                v-model="employee"
                                placeholder="employee..."
                                autocomplete="off"
                            />
                            <font-awesome-icon icon="magnifying-glass" class="searchBtn"/>
                        </div>
                        <div class="list-container">
                            <ul id="list">
                                <!-- Display selected employees -->
                                <li class="employee-name-list" v-for="employee in createData.selectedEmployees" :key="employee.Id">
                                <input
                                    type="checkbox"
                                    :value="employee"
                                    @change="toggleSelected(employee)"
                                    class="employee-checkbox"
                                    :id="'employee-checkbox-' + employee.Id"
                                    checked
                                >
                                <label :for="'employee-checkbox-' + employee.id">{{ employee.FirstName }}</label>
                                </li>
                                <!-- Display search results -->
                                <li class="employee-name-list" v-for="data in result" :key="data.Id">
                                <input
                                    type="checkbox"
                                    :value="data"
                                    @change="toggleSelected(data)"
                                    class="employee-checkbox"
                                    :id="'employee-checkbox-' + data.Id"
                                >
                                <label :for="'employee-checkbox-' + data.Id">{{ data.FirstName }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="recur-container">
                        <div id="recur-selection-container">
                         <div id="selection-title">
                             <h2>Type of Schedule:</h2>
                         </div>
                        <div id="selection-radio">
                            <input
                            type="radio"
                            id="repeating-rd"
                            name="repeat"
                            value="repeating"
                            v-model="isRepeatingVal"
                            >
                         <label for="repeat" id="repeating-lbl"> Repeating </label>
                         <input
                             type="radio"
                             id="not-repeating-rd"
                             name="not-repeat"
                             value="not"
                             v-model="isRepeatingVal"
                         >
                         <label for="not-repeat" id="repeating-lbl"> Not repeating</label>
                    </div>
                </div>
                <div id="days-container" v-if="createData.isRepeating">
                    <div>
                        <h2>Select Days:</h2>
                    </div>
                    <div id="sunday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('SUN')"
                        id="is-sunday"
                        value="0"
                        :checked="createData.days?.includes('SUN')"
                        name="sunday"
                        >
                        <label for="sunday"> Sunday</label>
                    </div>
                    <div id="monday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('M')"
                        id="is-monday"
                        :checked="createData.days?.includes('M')"
                        name="monday"
                        >
                        <label for="monday"> Monday</label>
                    </div>
                    <div id="tuesday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('T')"
                        id="is-tuesday"
                        :checked="createData.days?.includes('T')"
                        name="tuesday"
                        >
                        <label for="tuesday"> Tuesday</label>
                    </div>
                    <div id="wednesdat-div">
                        <input
                        type="checkbox"
                        @change="addToDays('W')"
                        id="is-wednesday"
                        :checked="createData.days?.includes('W')"
                        name="wednesday"
                        >
                        <label for="wednesday"> Wednesday</label>
                    </div>
                    <div id="thursday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('TH')"
                        id="is-thursday"
                        :checked="createData.days?.includes('TH')"
                        name="thursday"
                        >
                        <label for="thursday"> Thursday</label>
                    </div>
                    <div id="friday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('F')"
                        id="is-friday"
                        :checked="createData.days?.includes('F')"
                        name="friday"
                        >
                        <label for="friday"> Friday</label>
                    </div>
                    <div id="saturday-div">
                        <input
                        type="checkbox"
                        @change="addToDays('SAT')"
                        id="is-saturday"
                        :checked="createData.days?.includes('SAT')"
                        name="saturday"
                        >
                        <label for="saturday"> Saturday</label>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer>
      <button class="createBtn" @click="updateChanges">Update</button>
      <button class="cancelBtn" @click="deleteBtn">Delete</button>
    </footer>
    </div>
 </div>
</template>

<script>
import axios from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'change-detail-modal',
  props: {
    events: Object
  },
  data () {
    return {
      employee: '',
      isRepeatingVal: '',
      day: '',
      createData: {
        id: '',
        title: '',
        description: '',
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        selectedEmployees: [],
        isRepeating: null,
        days: []
      },
      result: [],
      addEmployee: ''
    }
  },
  watch: {
    isRepeatingVal (newVal, oldVal) {
      if (newVal.includes('not')) {
        this.createData.isRepeating = false
      } else if (oldVal.includes('not') && !newVal.includes('not')) {
        this.createData.isRepeating = true
      } else {
        this.createData.isRepeating = true
      }
    },
    employee (value) {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }
      if (value) {
        setTimeout(async () => {
          const list = await axios.get(`/employee/list?search=${value}`, config)
          this.result = list.data
        }, 2000)
      } else {
        this.result = []
      }
    }
  },
  computed: {
    eventsExtendedProps () {
      const numberToDayAbbreviation = {
        0: 'SUN',
        1: 'M',
        2: 'T',
        3: 'W',
        4: 'TH',
        5: 'F',
        6: 'SAT'
      }
      if (this.events && this.events._def) {
        let startDate = null
        let endDate = null
        if (this.events._def.extendedProps.startRecur) {
          startDate = new Date(this.events._def.extendedProps.startRecur)
          endDate = new Date(this.events._def.extendedProps.endRecur)
        } else {
          startDate = new Date(this.events._def.extendedProps.start)
          endDate = new Date(this.events._def.extendedProps.end)
        }
        if (this.events._def.groupId) {
          this.id = this.events._def.groupId
        } else {
          this.id = this.events._def.publicId
        }
        this.createData.title = this.events.title
        this.createData.description = this.events._def.extendedProps.description
        this.createData.selectedEmployees = this.events._def.extendedProps.selectedEmployees
        this.createData.startTime = this.formatTime(startDate)
        this.createData.startDate = this.formatDate(startDate)
        this.createData.endTime = this.formatTime(endDate)
        this.createData.endDate = this.formatDate(endDate)
        if (this.events._def.extendedProps.Days != null) {
          this.createData.days = this.events._def.extendedProps.Days.map(dayNumber => numberToDayAbbreviation[dayNumber])
        } else {
          this.createData.days = []
        }
        if (this.events._def.extendedProps.isRepeating === 'true') {
          this.isRepeatingVal = 'repeating'
        } else {
          this.isRepeatingVal = 'not'
        }
      }
    }
  },
  methods: {
    toggleSelected (employee) {
      // Check if the employee is already selected
      const index = this.createData.selectedEmployees.findIndex((e) => e.Id === employee.Id)

      if (index === -1) {
        // If not selected, add the employee to the array
        this.createData.selectedEmployees.push(employee)
      } else {
        // If already selected, remove the employee from the array
        this.createData.selectedEmployees.splice(index, 1)
      }
    },
    exitSched () {
      this.$emit('exitSched')
    },
    formatTime (date) {
      if (date instanceof Date) {
        // Format the date into a string in HH:mm:ss format
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')
        return `${hours}:${minutes}:${seconds}`
      } else {
        return ''
      }
    },
    formatDate (date) {
      if (date instanceof Date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
      } else {
        return ''
      }
    },
    addToDays (days) {
      const index = this.createData.days.indexOf(days)

      if (index === -1) {
        this.createData.days.push(days)
      } else {
        this.createData.days.splice(index, 1)
      }
    },
    async updateChanges () {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }

      try {
        const requestData = {
          SchedId: this.id,
          Title: this.createData.title,
          Description: this.createData.description,
          StartTime: this.createData.startTime,
          StartDate: this.createData.startDate,
          EndTime: this.createData.endTime,
          EndDate: this.createData.endDate,
          Repeating: this.createData.isRepeating.toString(),
          selectedEmployees: this.createData.selectedEmployees,
          Days: this.createData.days,
          Schedules: [] // Ensure all properties are included
        }
        console.log(requestData)
        const newSced = await axios.put('/employee/update/schedule', requestData, config)

        if (newSced.status === 200) {
          // handles the new data
          this.$emit('handleMessage', 'Schedule updated!!!')
          this.exitSched()
          this.$emit('success')
        } else {
          // handles error
          console.log(newSced)
        }
      } catch (e) {
        // handles deep error
        console.log(e)
      }
    },
    async deleteBtn () {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }
      const requestData = {
        SchedId: this.id
      }
      try {
        const deleteSched = await axios.put('/employee/delete/schedule', requestData, config)
        if (deleteSched.status === 200) {
          this.$emit('handleMessage', 'Schedule deleted!!!')
          this.exitSched()
          this.$emit('success')
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    return this.eventsExtendedProps
  }
})
</script>
