import { createStore } from 'vuex'
import { sessionStoragePlugin } from '../src/sessionPlugin'

export default createStore({
  state: {
    authData: {
      photoUrl: '',
      Uid: '',
      email: '',
      role: ''
    },
    userData: {
      firstName: '',
      middleName: '',
      lastName: '',
      personalEmail: '',
      PhoneNumber: '',
      id: ''
    },
    changeDetails: {
      personalEmail: '',
      firstName: '',
      middleName: '',
      lastName: '',
      PhoneNumber: '',
      id: ''
    },
    changeAuth: {
      photoUrl: '',
      Uid: '',
      email: '',
      role: ''
    }
  },
  mutations: {
    setAuthData (state, value) {
      state.authData = value
    },
    setUserData (state, value) {
      state.userData = value
    },
    setChangeDetail (state, value) {
      state.changeDetails = value
    },
    setChangesSaved (state, value) {
      state.changesSaved = value
    }
  },
  actions: {
    // Define the updateUserData action
    updateUserData ({ commit, state }, updatedUserData) {
      const currentUserData = { ...state.userData }

      // Loop through the properties in updatedUserData
      for (const property in updatedUserData) {
        // Check if the property is not null or undefined
        if (updatedUserData[property] !== null && updatedUserData[property] !== undefined) {
          // Update the corresponding property in currentUserData
          currentUserData[property] = updatedUserData[property]
        }
      }
      // Commit the updated user data to the store
      commit('setUserData', currentUserData)
    },
    // Define the updateUserData action
    updateAuth ({ commit, state }, updatedUserData) {
      const currentUserData = { ...state.authData }

      // Loop through the properties in updatedUserData
      for (const property in updatedUserData) {
        // Check if the property is not null or undefined
        if (updatedUserData[property] !== null && updatedUserData[property] !== undefined) {
          // Update the corresponding property in currentUserData
          currentUserData[property] = updatedUserData[property]
        }
      }
      // Commit the updated user data to the store
      commit('setAuthData', currentUserData)
    }
  },
  plugins: [sessionStoragePlugin]
})
