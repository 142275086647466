<template>
    <div class="show-employee-payroll-history">
        <PayslipTemp
        v-if="showPay"
        @exitPay="closePay"
        :employeeData="user[0]"
        @handleSuccess="handleMsg"
        ref="payslipModalRef"
      />
        <div class="data-container">
            <!--loading-->
            <div class="employee-data-loading" v-if="isLoading">
                <p>
                    <font-awesome-icon icon="spinner"/>
                    Loading...
                </p>
            </div>
            <div class="employee-data">
                <div class="employee-details">
                    <p>{{ 'Employee ID: ' + this.$route.params.userId }}</p>
                    <p>{{ 'Name: ' + user[0]?.firstName + ' '+ user[0]?.middleName + ' '+ user[0]?.lastName }}</p>
                    <p>{{'Cuttoff: '+ user[0]?.startDate + '-'+ user[0]?.endDate }}</p>
                </div>
                <div class="payroll-container-div">
                    <h1>SALARY COMPUTATION: </h1>
                    <div class="payroll-information">
                        <div id="input-container">
                            <label for="wage">Wage: </label>
                            <input type="number"
                                placeholder="Wage..."
                                id="input-input"
                                :value="user[0]?.wage || 0"
                                @input="updateWage"
                                name="wage"
                                step=0.01
                                disabled
                            />
                            <label for="bonus">Bonus: </label>
                            <input type="number"
                                placeholder="Bonus..."
                                id="input-input"
                                :value="user[0]?.bonus || 0"
                                @input="updateBonus"
                                name="bonus"
                                step=0.01
                                disabled
                            />
                            <label for="totalHour">Total Hour: </label>
                            <input
                              type="text"
                              placeholder="Over time..."
                              id="input-input"
                              :value="formattedTotalHours"
                              name="totalHour"
                              disabled
                            />
                        </div>
                        <div id="input-container">
                            <label for="Over time">Over time: </label>
                            <input
                              type="text"
                              placeholder="Over time..."
                              id="input-input"
                              :value="formattedOverTime"
                              name="totalHour"
                              disabled
                            />
                            <label for="late">Late (minutes): </label>
                            <input type="number"
                                placeholder="Late..."
                                id="input-input"
                                :value="user[0]?.lateMinute || 0"
                                name="late"
                                step=0.01
                                disabled
                            />
                            <label for="deduction">Deduction: </label>
                            <input type="number"
                                placeholder="Deduction..."
                                id="input-input"
                                :value="user[0]?.NeWdeductions || 0"
                                @input="updateNewDeduction"
                                name="bonus"
                                step=0.01
                                disabled
                            />
                            <label for="deduction-description">Description: </label>
                            <input type="text"
                                placeholder="No Deductions"
                                id="input-input"
                                :value="user[0]?.deductionsDescription"
                                @input="updateDeductionsDescription"
                                disabled
                            />
                        </div>
                        <div id="input-container">
                            <label for="pagibig">Pag-Ibig: </label>
                            <input type="number"
                                placeholder="pag-ibig..."
                                id="input-input"
                                :value="user[0]?.pagibig || 0"
                                @input="updateNewPagibig"
                                name="pagibig"
                                step=0.01
                                disabled
                            />
                            <label for="pagiIbig">SSS: </label>
                            <input type="number"
                                placeholder="SSS..."
                                id="input-input"
                                :value="user[0]?.sss || 0"
                                @input="updateNewSSS"
                                name="bonus"
                                step=0.01
                                disabled
                            />
                            <label for="philHealth">Philhealth: </label>
                            <input type="number"
                                placeholder="PhilHealth..."
                                id="input-input"
                                :value="user[0]?.philhealth || 0"
                                @input="updateNewPhilHealth"
                                name="philHealth"
                                step=0.01
                                disabled
                            />
                        </div>
                    </div>
                    <div class="button-div">
                        <button @click="generatePaySlip">GENERATE PAYSLIP</button>
                        <button @click="backBtn">CLOSE</button>
                    </div>
                    <div class="gross-div">
                        <p>GROSS PAY:</p>
                        <p>{{ user[0]?.grossEarnings +' PhP' || 0.00 +' PhP' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import PayslipTemp from '@/modal/PayslipTemp.vue'

export default (await import('vue')).defineComponent({
  name: 'employee-history-payroll',
  data () {
    return {
      user: [],
      isLoading: true,
      showPay: false,
      msg: ''
    }
  },
  components: {
    PayslipTemp
  },
  computed: {
    formattedOverTime () {
      if (this.user[0]) {
        const totalMinutes = this.user[0].overTimeHour * 60 + this.user[0].overTimeMinute
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
      }
      return '0:00'
    },
    formattedTotalHours () {
      if (this.user[0]) {
        const totalMinutes = this.user[0].hour * 60 + this.user[0].remainingMinute
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
      }
      return '0:00'
    }
  },
  methods: {
    generatePaySlip () {
      this.showPay = true
      this.$nextTick(() => {
        this.$refs.payslipModalRef.$el.focus()
      })
    },
    async getUserPayroll () {
      const cookieValue = sessionStorage.getItem('token')
      const params = { id: this.$route.params.userId }

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      try {
        const user = await axios.get('/payroll/user/payroll-history', { params, ...config })
        if (user.status === 200) {
          console.log(user.data)
          this.user = user.data
          this.isLoading = false
        }
        console.log(this.user)
      } catch (e) {
        console.log(e)
      }
    },
    closePay () {
      this.showPay = false
    },
    backBtn () {
      this.$router.back(-1)
    }
  },
  created () {
    this.getUserPayroll()
  }
})
</script>
