<template>
    <div class="change-password-modal">
        <div id="modal-body">
            <header>
                <p id="change-detail-title">Change Password</p>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
            </header>
            <main>
                <div id="msg-box">
                    <p>{{ message }}</p>
                </div>
                <div id="old-password-div">
                    <label for="old-password-input">Old Password: </label>
                    <input
                        type="password"
                        v-model="oldPassword"
                        placeholder="Your old password"
                        autocomplete="off"
                    />
                </div>
                <div id="new-password-div">
                    <label for="new-password-input">New Password: </label>
                    <input
                        type="password"
                        v-model="newPassword"
                        placeholder="Your new password"
                        autocomplete="off"
                    />
                </div>
                <div id="new-password-div">
                    <label for="new-password-input">New Password: </label>
                    <input
                        type="password"
                        v-model="newPasswordVerify"
                        placeholder="Your new password"
                        autocomplete="off"
                    />
                </div>
            </main>
            <footer>
                <button @click="changePassSubmit"
                        class="changePassSubmit"
                        :disabled="!approve">
                        success
                </button>
                <button @click="changePassCancel"
                        class="changePassCancel"
                        :disabled="!oldPassword && !newPassword && !newPasswordVerify">
                        cancel
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'

export default (await import('vue')).defineComponent({
  name: 'change-password',
  data () {
    return {
      match: false,
      empty: false,
      approve: false,
      newPassword: '',
      newPasswordVerify: '',
      oldPassword: '',
      message: ''
    }
  },
  watch: {
    newPasswordVerify: {
      deep: true,
      handler (newVal) {
        const newPasswordDiv = document.querySelectorAll('#new-password-div')
        const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/
        this.approve = regex.test(newVal)
        newPasswordDiv.forEach(div => {
          if (div.classList.contains('success')) {
            div.classList.remove('success')
          } else if (div.classList.contains('error')) {
            div.classList.remove('error')
          }
        })
        if (this.approve === true) {
          this.match = this.newPassword === newVal

          if (newVal === null || this.newPassword === null) {
            this.match = false
            this.empty = true
          } else {
            this.match = this.newPassword === newVal
            this.empty = true
          }
          setTimeout(() => {
            if (this.match === true) {
              newPasswordDiv.forEach(div => {
                div.classList.add('success')
              })
              this.message = ''
            } else if (this.empty === false && this.match === false) {
              newPasswordDiv.forEach(div => {
                div.classList.add('error')
              })
            } else if (this.empty === true) {
              newPasswordDiv.forEach(div => {
                if (div.classList.contains('success')) {
                  div.classList.remove('success')
                } else if (div.classList.contains('error')) {
                  div.classList.remove('error')
                }
              })
            }
          }, 3000)
        } else {
          setTimeout(() => {
            this.message = 'Password must contain 1 Capital letter and atleast 1 number and 8 characters'
            newPasswordDiv.forEach(div => {
              div.classList.add('error')
            })
          }, 3000)
        }
      }
    }
  },
  methods: {
    async changePassSubmit () {
      try {
        const auth = getAuth()
        const user = auth.currentUser
        const email = auth.currentUser.email
        const passwordVerify = await signInWithEmailAndPassword(auth, email, this.oldPassword)
        console.log(passwordVerify)
        if (passwordVerify.user != null) {
          if (this.match === true) {
            updatePassword(user, this.newPassword)
              .then(() => {
                this.message = 'password changed!'
                setTimeout(() => {
                  this.exitModal()
                  this.$emit('success')
                }, 2000)
              })
              .catch(() => {
                this.message = 'Invalid Password!'
              })
          }
        }
      } catch (e) {
        this.message = 'Invalid Password!'
      }
    },
    changePassCancel () {
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordVerify = ''
      this.exitModal()
    },
    exitModal () {
      this.$emit('exitModal')
    }

  }
})
</script>
