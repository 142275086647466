<template>
  <div class="payroll-div">
    <SuccessModal
        v-if="showSuccess"
        :message="msg"
        @exitSuccess="closeModal"/>
      <ErrorModal
        v-if="showError"
        :message="msg"
        @exitModal="closeModal"/>
      <div class="payroll-header">
        <div class="search-div">
          <input
            type="search"
            placeholder="search"
            name="search"
            id="search"
            v-model="searchQuery"
            autocomplete="off">
            <font-awesome-icon icon="magnifying-glass" class="searchBtn"/>
        </div>
        <div class="filter-options">
          <div class="grossEarning">
            <label for="bygross"> By Gross Pay:</label>
            <select name="bygross" id="bygross" v-model="gross">
              <option value="" selected>default</option>
              <option value="up">high to low</option>
              <option value="low">low to high</option>
            </select>
          </div>
        </div>
        <div class="button-div">
          <button class="release-payroll" @click="releasePayroll">RELEASE PAYROLL</button>
          <button class="generate-payroll" @click="generatePayroll">GENERATE PAYROLL</button>
        </div>
      </div>
      <div class="pay-content-div">
        <div id="data-title">
          <p>EMPLOYEE NAME</p>
          <p>CUTOFF DATE</p>
          <p>GROSS PAY</p>
          <p>INFO</p>
        </div>
          <div id="loading" v-if="isloading">
            <font-awesome-icon icon="spinner"/>
            <p>Loading</p>
          </div>
          <div id="noData" v-else-if="noData">
            <font-awesome-icon icon="inbox"/>
            <p>No Data</p>
          </div>
          <div id="payroll-data" v-else v-for="data in computedPayroll" :key="data.id">
            <p>{{ data.firstName }}</p>
            <p>{{ data.startDate  + '-' + data.endDate|| 0 }}</p>
            <p>{{ data.grossEarnings  || 0.00 }}</p>
            <button class="show-info" @click="showEmployeeInfo(data.id)">
              <font-awesome-icon icon="info" class="searchBtn"/>
            </button>
          </div>
      </div>
      <div class="pay-footer-div">
        <PaginationComponent
          :totalPages="totalPages"
          :perPage="10"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          />
      </div>
  </div>
</template>

<script>
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import axios from '@/api/axios'
export default (await import('vue')).defineComponent({
  name: 'PayrollModule',
  data () {
    return {
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 5,
      payroll: [],
      searchQuery: '',
      gross: '',
      isloading: true,
      noData: false,
      showSuccess: false,
      showError: false,
      msg: ''
    }
  },
  components: {
    PaginationComponent,
    SuccessModal,
    ErrorModal
  },
  computed: {
    computedPayroll () {
      const search = this.searchQuery.trim().toLowerCase()
      const bygross = this.gross
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Check if the search query is not empty
      if (search.length > 0) {
        // Use the filter method to create a new array based on the search query
        const filteredPayroll = this.payroll.filter(
          (e) =>
            e.firstName.toLowerCase().includes(search) ||
        e.lastName.toLowerCase().includes(search)
        )

        const sortedPayroll = this.sortByGross(filteredPayroll, bygross)
        this.totalItems = sortedPayroll.length

        return sortedPayroll.slice(startIndex, endIndex)
      } else {
        // If search query is empty, return the original payroll data
        const sortedPayroll = this.sortByGross(this.payroll, bygross)

        this.totalItems = sortedPayroll.length
        return sortedPayroll.slice(startIndex, endIndex)
      }
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    }
  },
  methods: {
    closeModal () {
      this.showError = false
      this.showSuccess = false
    },
    showEmployeeInfo (id) {
      this.$router.push(`payroll/view-payroll/${id}`)
    },
    onPageChange (page) {
      this.currentPage = page
    },
    async getPayroll () {
      const cookieValue = sessionStorage.getItem('token')

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const payrollRes = await axios.get('/payroll/all-payroll', config)
        if (payrollRes.status === 200) {
          this.payroll = payrollRes.data
        }
        if (this.payroll.length > 0) {
          this.isloading = false
          this.noData = false
        } else {
          this.isloading = false
          this.noData = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    async releasePayroll () {
      this.isloading = true
      const cookieValue = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const releaseRes = await axios.post('/payroll/release-payroll', '', config)
        if (releaseRes.status === 200) {
          this.msg = 'Payroll released!!!'
          this.showSuccess = true
          this.getPayroll()
        }
      } catch (e) {
        console.log(e)
        this.msg = 'Failed to release'
        this.showError = true
      }
    },
    async generatePayroll () {
      this.isloading = true
      const cookieValue = sessionStorage.getItem('token')
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()

      let startCuttoff
      let endCuttoff

      if (currentDate.getDate() <= 15) {
        startCuttoff = new Date(currentYear, currentMonth, 1)
        endCuttoff = new Date(currentYear, currentMonth, 15)
      } else {
        startCuttoff = new Date(currentYear, currentMonth, 15)
        endCuttoff = new Date(currentYear, currentMonth + 1, 0)
      }

      // Get the date part in the format Dec 1, 2023
      const formattedStartDateString = startCuttoff.toLocaleDateString('en-PH', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })

      const formattedEndDateString = endCuttoff.toLocaleDateString('en-PH', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      const date = { startDate: formattedStartDateString, endDate: formattedEndDateString }
      try {
        const payrollRes = await axios.post('/payroll/generate-payroll', date, config)
        if (payrollRes.status === 200) {
          this.msg = 'Payroll generated!!!'
          this.showSuccess = true
          this.getPayroll()
        }
      } catch (e) {
        this.msg = 'Failed to release'
        this.showError = true
      }
    },
    sortByGross (data, bygross) {
      if (bygross === 'up') {
        // Sort data in ascending order based on grossEarnings
        return data.slice().sort((a, b) => b.grossEarnings - a.grossEarnings)
      } else if (bygross === 'low') {
        // Sort data in descending order based on grossEarnings
        return data.slice().sort((a, b) => a.grossEarnings - b.grossEarnings)
      } else {
        // Default: return data as is
        return data
      }
    }
  },
  created () {
    this.getPayroll()
  }
})
</script>
