import Attendance from '@/views/AttendanceModule.vue'
import AttendanceRep from '@/views/admin-view/AttendanceReportModule.vue'
import mainComponent from '@/views/admin-view/AdminMainDash.vue'
import scheduleModule from '@/views/admin-view/ScheduleModule.vue'
import payrollModule from '@/views/admin-view/PayrollModule.vue'
import employeeListModule from '@/views/admin-view/EmployeeListView.vue'
import userSettings from '@/components/UserPage.vue'
import viewUser from '@/views/admin-view/ViewEmployee.vue'
import UserPayroll from '@/components/admin-components/UserPayroll.vue'
import PayrollHistoty from '@/views/admin-view/PayrollHistory.vue'
import UserPayrollHistory from '@/views/admin-view/viewEmployeePayroll.vue'

const authRoleGuard = (to, from, next) => {
  const isAuthenticated = sessionStorage.getItem('token') !== null
  const role = sessionStorage.role
  const mainTitle = []

  // Updates the navTitle base on url
  document.addEventListener('DOMContentLoaded', () => {
    const navTitle = document.querySelector('.system-title')
    mainTitle.push(navTitle.textContent)

    if (to.path === '/admin/attendance-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - ATTENDANCE RECORD`
    } else if (to.path === '/admin/attendance-report') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - ATTENDANCE REPORT`
    } else if (to.path === '/admin/schedule-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - SCHEDULE`
    } else if (to.path === '/admin/payroll-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - PAYROLL`
    } else if (to.path === '/admin/employee-list') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - EMPLOYEES`
    } else if (to.path === '/admin/user/:userId') {
      navTitle.textContent = ''
    }
  })

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/')

    // Redirect to the appropriate route if user wants to go back to login
  } else if (from.path === '/admin/attendance-module' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/admin/attendance-report' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/admin/schedule-module' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/admin/payroll-module' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/admin/employee-list' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/admin/attendance-module' && to.path === '/employee') {
    next('/' + role)
  } else if (from.path === '/admin/attendance-report' && to.path === '/employee') {
    next('/' + role)
  } else if (from.path === '/admin/schedule-module' && to.path === '/employee') {
    next('/' + role)
  } else if (from.path === '/admin/payroll-module' && to.path === '/employee') {
    next('/' + role)
  } else if (from.path === '/admin/employee-list' && to.path === '/employee') {
    next('/' + role)
  } else if (from.path === '/admin/user/:userId' && to.path === '/employee') {
    next('/' + role)
  } else if (to.meta.role && to.meta.role !== role) {
    next('/' + role)
  } else {
    next()
  }
}

export default [
  {
    path: '',
    children: [
      {
        path: '',
        name: 'mainComponent',
        component: mainComponent,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'user-settings',
        component: userSettings,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'attendance-module',
        name: 'AttendanceModule',
        component: Attendance,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'attendance-report',
        name: 'AttendanceReportModule',
        component: AttendanceRep,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'schedule-module',
        name: 'scheduleModule',
        component: scheduleModule,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'payroll-module',
        name: 'payrollModule',
        component: payrollModule,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'employee-list',
        name: 'employeeList',
        component: employeeListModule,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'user/:userId',
        name: 'employee-profile',
        component: viewUser,
        props: true,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'payroll/view-payroll/:userId',
        name: 'employee-payroll',
        component: UserPayroll,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'payroll/history',
        name: 'payroll-history',
        component: PayrollHistoty,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'payroll/user-history/:userId',
        name: 'employee-history-payroll',
        component: UserPayrollHistory,
        meta: {
          role: 'admin',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      }
    ]
  }
]
