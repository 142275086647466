<template>
    <div class="success-timeIn-modal">
        <div id="modal-body">
            <header>
                <p>AUTHENTICATION</p>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitFingerprint"/>
            </header>
            <main>
                <div class="processing-container">
                    <img
                    class="fingerprint-img"
                    src="../assets/fingerprint.png"
                    alt="fingerprint">
                    <h1>{{ message }}</h1>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'time-in-modal',
  props: {
    message: String,
    class: String
  },
  methods: {
    exitFingerprint () {
      this.$emit('close')
    }
  },
  created () {
    this.$nextTick().then(() => {
      const img = document.querySelector('.fingerprint-img')

      img.classList.add(this.class)
    })
  }
})
</script>
