import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from 'firebase/app'
import router from './router'

import './assets/styles/style.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faArrowRightFromBracket,
  faBars,
  faBuildingUser,
  faCalendar,
  faCamera,
  faCaretDown,
  faClipboardUser,
  faCopy,
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faMoneyBill,
  faMoneyBillWave,
  faUserCheck,
  faUserClock,
  faUsers,
  faXmark,
  faMagnifyingGlass,
  faInbox,
  faSpinner,
  faTrash,
  faCircleCheck,
  faCircleXmark,
  faInfo,
  faCalendarXmark,
  faReceipt,
  faMoneyCheckDollar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store'

library.add(faBars)
library.add(faXmark)
library.add(faArrowRightFromBracket)
library.add(faCaretDown)
library.add(faUserCheck)
library.add(faBuildingUser)
library.add(faMoneyBillWave)
library.add(faMoneyBill)
library.add(faClipboardUser)
library.add(faUserClock)
library.add(faUsers)
library.add(faCalendar)
library.add(faLock)
library.add(faEnvelope)
library.add(faEye)
library.add(faEyeSlash)
library.add(faArrowLeft)
library.add(faCopy)
library.add(faCamera)
library.add(faMagnifyingGlass)
library.add(faInbox)
library.add(faSpinner)
library.add(faTrash)
library.add(faCircleCheck)
library.add(faCircleXmark)
library.add(faInfo)
library.add(faCalendarXmark)
library.add(faReceipt)
library.add(faMoneyCheckDollar)

const firebaseConfig = {
  apiKey: 'AIzaSyDUCQBgCDhINUlX4w7hUYV8ZtH_lPabJo4',
  authDomain: 'hrisps---wagyuniku.firebaseapp.com',
  databaseURL: 'https://hrisps---wagyuniku-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'hrisps---wagyuniku',
  storageBucket: 'hrisps---wagyuniku.appspot.com',
  messagingSenderId: '142088230867',
  appId: '1:142088230867:web:3e92078089ffed46f54126',
  measurementId: 'G-C08N7K4ELN'
}

initializeApp(firebaseConfig)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(store)
  .mount('#app')
