<template>
    <div class="addEmployee-modal">
      <RegisterFingerPrint
      v-if="showReg"
      :newUserId="employeeCount"
      @fingerprintRegistered="handleFingerprintRegistered"
      @closeModal="exitRegisterFingerprint"
      />
      <div id="modal-body">
        <header>
          <h1>Add new Employee</h1>
          <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
        </header>
        <main>
          <form id="details-form">
            <div id="employee-id-div">
              <label for="employee-id">Employee Id:</label>
                  <input
                      type="text"
                      placeholder="Employee Id..."
                      name="employee-id"
                      id="employee-id"
                      v-model="details.id"
                      autocomplete="off"
                      required
                      />
              <font-awesome-icon class="register-icon" icon="circle-check" v-if="notExisit"/>
              <font-awesome-icon class="notRegister-icon" icon="circle-xmark" v-else/>
            </div>
            <div id="firstName-div">
              <label for="firstName">First Name:</label>
                  <input
                      type="text"
                      placeholder="first name"
                      name="firstName"
                      id="firstName"
                      v-model="details.firstName"
                      autocomplete="off"
                      required
                      />
              <font-awesome-icon class="register-icon" icon="circle-check" v-if="notExisit"/>
              <font-awesome-icon class="notRegister-icon" icon="circle-xmark" v-else/>
            </div>
            <div id="middleName-div">
              <label for="middleName">Middle Name:</label>
                  <input
                      type="text"
                      placeholder="Middle name"
                      name="middleName"
                      id="middleName"
                      v-model="details.middleName"
                      autocomplete="off"
                      required
                      />
            </div>
            <div id="lastName-div">
              <label for="lastName">Last Name:</label>
                  <input
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      id="lastName"
                      v-model="details.lastName"
                      autocomplete="off"
                      required
                      />
            </div>
            <div id="emailInput-div">
              <label for="email">Email:</label>
                  <input
                      type="text"
                      placeholder="Personal Email"
                      name="email"
                      id="email"
                      v-model="details.personalEmail"
                      autocomplete="off"
                      required
                      />
            </div>
            <div id="position-div">
              <label for="position">Station:</label>
                  <select name="position" id="position" v-model="station">
                    <option value="FOH">Front of the house</option>
                    <option value="BOH">Back of the house</option>
                  </select>
            </div>
            <div id="foh-position-div" v-if="isFoh">
              <label for="foh-position">Front of the house: </label>
                  <select name="foh-position" id="foh-position" v-model="details.role">
                    <option value="barista">Barista</option>
                    <option value="cashier">Cashier</option>
                    <option value="admin">Restaurant Manager(admin)</option>
                    <option value="server">Server</option>
                    <option value="security guard">Security Guard</option>
                  </select>
            </div>
            <div id="boh-position-div" v-if="isBoh">
              <label for="boh-position">Back of the house: </label>
                  <select name="boh-position" id="boh-position" v-model="details.role">
                    <option value="head chef">Head Chef</option>
                    <option value="line cook">Line Cook</option>
                    <option value="headAdmin">head Manager</option>
                  </select>
            </div>
            <div id="status-div">
              <label for="status">Status:</label>
                  <select name="status" id="status" v-model="details.type">
                    <option value="Onboarding">Onboarding</option>
                    <option value="Outboarding">Outboarding</option>
                    <option value="Probationary">Probation</option>
                    <option value="Regular">Regular</option>
                    <option value="Suspended">Suspended</option>
                  </select>
            </div>
            <div id="calendar-birthDate-div">
              <label for="calendar">Birth Date: </label>
                      <input
                      id="birthDate"
                      name="calendar"
                      v-model="details.birthDate"
                      type="date"
                      required
                      />
            </div>
            <div id="phone-number-div">
              <label for="phone">Phone Number:</label>
                      <input
                        type="tel"
                        placeholder="Ex. +63 09999999999"
                        id="phone"
                        name="phone"
                        v-model="details.phoneNumber"
                        @input="formatPhoneNumber"
                        pattern="^\+\d{1,4}\s?\d{9}$"
                        title="Please enter a valid phone number with the country code, e.g., +1234 123456789"
                        required
                      />
            </div>
            <div id="wage-div">
              <label for="wage">Wage:</label>
                      <input
                        type="number"
                        placeholder="Wage... (Ex. 75.50)"
                        id="wage"
                        v-model="details.wage"
                        name="wage"
                        step=0.01
                        required
                      />
            </div>
          </form>
          <form id="profile-form">
            <div class="profile-div">
              <img class="profile-img" :src="this.details.photoUrl" alt="User Avatar"/>
              <label for="uploadBtn" id="uploadBtnLbl">
                  <font-awesome-icon icon="camera"/>
                  Change profile image
                </label>
              <input
                type="file"
                name="uploadBtn"
                id="uploadBtn"
                @change="avatar"
                hidden
                />
            </div>
            <div class="fingerprint-container">
              <button class="registerFingerprintBtn" @click="registerFingerPrintBtn" :disabled="registered || !hasFingerprint">Register Fingerprint</button>
              <font-awesome-icon class="register-icon" icon="circle-check" v-if="registered"/>
              <font-awesome-icon class="notRegister-icon" icon="circle-xmark" v-else/>
            </div>
          </form>
        </main>
        <footer>
          <button class="addEmployeeBtn" @click="addEmployeeBtn" :disabled="!add || !notExisit">Add Employee</button>
          <button @click="cancelBtn" class="cancelBtn">Cancel</button>
        </footer>
      </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import RegisterFingerPrint from './RegisterFingerPrint.vue'

export default (await import('vue')).defineComponent({
  name: 'addEmployee-modal',
  components: {
    RegisterFingerPrint
  },
  props: {
    employeeCount: Number
  },
  data () {
    return {
      allUser: [],
      details: {
        id: '',
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        role: '',
        personalEmail: '',
        photoUrl: '',
        birthDate: '',
        wage: '',
        type: '',
        fingerId: ''
      },
      isFoh: false,
      isBoh: false,
      fohRole: '',
      bohRole: '',
      station: '',
      showReg: false,
      registered: false,
      add: false,
      notExisit: false,
      hasFingerprint: false
    }
  },
  watch: {
    details: {
      deep: true,
      handler (newVal) {
        if (newVal.id === '' && newVal.firstName === '') {
          this.notExisit = false
        } else if (newVal.id && newVal.firstName) {
          this.checkIfUserExists(newVal.id, newVal.firstName)
        }
        if (newVal.role === '') {
          this.hasFingerprint = false
        } else if (newVal.role === 'headAdmin' || newVal.role === 'admin') {
          this.hasFingerprint = false
        } else {
          this.hasFingerprint = true
        }
      }
    },
    station: {
      deep: true,
      handler (newVal) {
        switch (newVal) {
          case 'FOH':
            this.isBoh = false
            this.isFoh = true
            break
          case 'BOH':
            this.isFoh = false
            this.isBoh = true
            break
        }
      }
    },
    fohRole: {
      deep: true,
      handler (newVal) {
        this.details.role = newVal
      }
    },
    bohRole: {
      deep: true,
      handler (newVal) {
        this.details.role = newVal
      }
    }
  },
  computed: {
    formattedPhoneNumber: {
      get () {
        return this.formatInputPhoneNumber(this.details.phoneNumber)
      },
      set (value) {
        // Set the value as is, as it has already been formatted by the user
        this.details.phoneNumber = value
      }
    }
  },
  methods: {
    formatPhoneNumber () {
      // Format the input value while typing
      this.details.phoneNumber = this.formatInputPhoneNumber(this.details.phoneNumber)
    },
    formatInputPhoneNumber (value) {
      // Remove leading zeros and add +63 if it doesn't exist
      const formattedValue = value.replace(/^0/, '').trim()
      return formattedValue.startsWith('+63') ? formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3') : `+63 ${formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`
    },
    async getAllUsersData () {
      const cookieValue = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const data = await axios.get('/auth/creds', config)
        this.allUser = data.data
      } catch (e) {
        this.$emit('exitModal')
      }
    },
    checkIfUserExists (id, firstName) {
      const matchingId = this.allUser.find(
        (user) => user.id === id
      )
      const matchingFirstName = this.allUser.find(
        (user) => user.firstName === firstName
      )
      this.add = !matchingId && !matchingFirstName
      if (matchingId || matchingFirstName) {
        this.notExisit = false
      } else {
        this.notExisit = true
      }
    },
    registerFingerPrintBtn (e) {
      e.preventDefault()
      this.showReg = true
    },
    handleFingerprintRegistered (id, status) {
      this.details.fingerId = id.id
      if (status === 'success') {
        this.registered = true
      }
    },
    async avatar (e) {
      e.preventDefault()
      const file = e.target.files[0]
      // Use FileReader to read the image and set userDetails.avatar
      const reader = new FileReader()
      reader.onload = (event) => {
        this.details.photoUrl = event.target.result
      }

      reader.readAsDataURL(file)
    },
    exitModal () {
      this.$emit('exitModal')
    },
    async addEmployeeBtn () {
      console.log(this.details)
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }

      try {
        if (this.details.role.match('restaurant manager')) {
          console.log('admin')
          const addResponse = await axios.post('/admin/register/admin-register', this.details, config)

          if (addResponse.status === 200) {
            this.$emit('handleMessage', 'Admin added')
            this.$emit('submit')
          }
        } else if (this.details.role.match('headAdmin')) {
          const addResponse = await axios.post('/admin/register/headAdmin-register', this.details, config)

          if (addResponse.status === 200) {
            this.$emit('handleMessage', 'Head admin added')
            this.$emit('submit')
          }
        } else if (this.details.role.match('head chef') ||
                    this.details.role.match('line cook') ||
                    this.details.role.match('cashier') ||
                    this.details.role.match('barista') ||
                    this.details.role.match('server') ||
                    this.details.role.match('security guard')) {
          const addResponse = await axios.post('/admin/register/employee-register', this.details, config)
          if (addResponse === 200) {
            this.$emit('handleMessage', 'Employee added')
            this.$emit('submit')
          }
        }
      } catch (e) {
        console.log(e)
        this.$emit('handleMessage', 'Failed to add employee')
        this.$emit('error')
      }
    },
    exitRegisterFingerprint () {
      this.showReg = false
    }
  },
  created () {
    this.getAllUsersData()
  }
})
</script>
