<template>
    <div class="attendance-modal">
        <div class="modal-body">
            <header>
                <h1 class="title">ADD ATTENDANCE</h1>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
            </header>
            <main>
                <div class="type">
                    <label for="selectType">Type: </label>
                    <select name="selecteType" id="selecteType" v-model="details.type">
                        <option value="present">PRESENT</option>
                        <option value="late">LATE</option>
                        <option value="break">BREAK</option>
                        <option value="break end">END BREAK</option>
                        <option value="out">OUT</option>
                        <option value="overtime">OVERTIME</option>
                        <option value="overtime out">OVERTIME OUT</option>
                    </select>
                </div>
                <div class="firstName">
                    <label for="firstName">First Name: </label>
                    <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Employee first name"
                    v-model="details.firstName"
                    required>
                </div>
                <div class="dateToday">
                    <label for="dateToday">Date: </label>
                    <input
                    type="date"
                    name="dateToday"
                    v-model="details.dateToday"
                    required>
                </div>
                <div class="time">
                    <label for="time">Time: </label>
                    <input
                    type="time"
                    name="time"
                    v-model="details.time"
                    required>
                </div>
                <div class="late">
                  <label for="late">Late (minute): </label>
                  <input
                    type="text"
                    name="late"
                    id="late"
                    pattern="[0-9]+"
                    placeholder="Enter late in minutes"
                    v-model="details.lateMinute"
                    required
                  />
                </div>
            </main>
            <footer>
              <button class="confirmBtn" @click="addAttendance" :disabled="!allow && submit">SUBMIT</button>
              <button class="cancelBtn" @click="cancelAttendance">CANCEL</button>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'add-attendance',
  data () {
    return {
      details: {
        id: null,
        firstName: '',
        dateToday: '',
        type: '',
        time: '',
        lateMinute: 0,
        isHoliday: ''
      },
      timeInTime: null,
      breakStartTime: null,
      breakEndTime: null,
      overTimeStartTime: null,
      outTime: null,
      add: false,
      msg: '',
      allow: false,
      submit: true
    }
  },
  watch: {
    'details.dateToday': {
      handler: 'checkHoliday', // Call the checkHoliday method when details.dateToday changes
      immediate: true // Call the handler immediately when the component is created
    },
    'details.id': {
      handler: 'checkUser',
      immediate: true
    }
  },
  methods: {
    async checkUser () {
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      try {
        const allEmployee = await axios.get('/auth/creds', config)
        if (allEmployee.status === 200 && allEmployee.data !== null) {
          this.details.id = allEmployee.data.find(
            (user) => user.firstName === this.details.firstName
          )
          if (this.details.id !== undefined) {
            console.log('user')
            this.allow = true
          }
        }
      } catch (e) {
        this.allow = false
      }
    },
    handleDateToday () {
      const month = String(new Date().getMonth() + 1).padStart(2, '0')
      const day = String(new Date().getDate()).padStart(2, '0')
      const year = String(new Date().getFullYear())
      this.details.dateToday = `${year}-${month}-${day}`
    },
    exitModal () {
      this.$emit('close')
    },
    async Attendance () {
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      const option2 = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Manila'
      }
      try {
        const attendance = await axios.get('/Attendance/records', config)
        if (attendance.data !== null) {
          const filteredAttendance = attendance.data.filter(record => {
            return (
              record.date === new Date(this.details.dateToday).toLocaleDateString('en-Ph', option2) &&
                record.firstName === this.details.firstName
            )
          })
          if (this.timeInTime === null && filteredAttendance.length > 0) {
            const recordWithTimeIn = filteredAttendance.find(a => a.timeIn !== null)

            if (recordWithTimeIn) {
              this.timeInTime = `${recordWithTimeIn.date}T${recordWithTimeIn.timeIn}`
            }
          }
          if (this.outTime === null && filteredAttendance.length > 0) {
            const recordWithTimeIn = filteredAttendance.find(a => a.out !== null)

            if (recordWithTimeIn) {
              this.outTime = `${recordWithTimeIn.date}T${recordWithTimeIn.out}`
            }
          }
          if (this.breakEndTime === null && filteredAttendance.length > 0) {
            const recordWithTimeIn = filteredAttendance.find(a => a.breakEnd !== null)

            if (recordWithTimeIn) {
              this.breakEndTime = `${recordWithTimeIn.date}T${recordWithTimeIn.breakEnd}`
            }
          }
          if (this.breakEndTime === null && filteredAttendance.length > 0) {
            const recordWithTimeIn = filteredAttendance.find(a => a.breakEnd !== null)

            if (recordWithTimeIn) {
              this.breakEndTime = `${recordWithTimeIn.date}T${recordWithTimeIn.breakEnd}`
            }
          }
          if (this.overTimeStartTime === null && filteredAttendance.length > 0) {
            const recordWithTimeIn = filteredAttendance.find(a => a.overTimeStart !== null)

            if (recordWithTimeIn) {
              this.overTimeStartTime = `${recordWithTimeIn.date}T${recordWithTimeIn.overTimeStart}`
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addAttendance () {
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      const option2 = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Manila'
      }
      const option3 = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Manila'
      }
      const option4 = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Manila'
      }
      try {
        if (this.timeInTime === null && this.details.type === 'present') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            timeIn: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option3),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          const timeIn = await axios.post('Attendance/user/time', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'Time In Added')
            this.$emit('success')
          }
        } else if (this.timeInTime === null &&
                this.details.type === 'late') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            timeIn: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option3),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          const timeIn = await axios.post('Attendance/user/time', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'Time in added!')
            this.$emit('success')
          }
        } else if (this.timeInTime !== null &&
                this.breakStartTime === null &&
                this.details.type === 'break') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            breakStart: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option3),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          const timeIn = await axios.post('Attendance/user/break-start', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'break added')
            this.$emit('success')
          }
        } else if (this.breakStartTime !== null &&
                this.breakEndTime === null &&
                this.details.type === 'break end') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            breakEnd: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option3),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          const timeIn = await axios.post('Attendance/user/break-end', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'break end added')
            this.$emit('success')
          }
        } else if (this.breakEndTime !== null &&
                this.details.type === 'out') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            timeOut: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option4),
            timeIn: this.timeInTime,
            breakStart: this.breakStartTime,
            breakEnd: this.breakEndTime,
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          console.log(request)
          const timeIn = await axios.post('Attendance/user/out', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'Out added')
            this.$emit('success')
          }
        } else if (this.outTime !== null &&
                this.overTimeStartTime === null &&
                this.details.type === 'overtime') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            overTimeStart: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option3),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          console.log(request)
          const timeIn = await axios.post('Attendance/user/overTimeStart', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'Ovetime added')
            this.$emit('success')
          }
        } else if (this.overTimeStartTime !== null &&
                this.details.type === 'overtime out') {
          const request = {
            date: new Date(this.details.dateToday).toLocaleDateString('en-PH', option2),
            id: this.details.id,
            overTimeStart: this.overTimeStartTime,
            overTimeEnd: new Date(`${this.details.dateToday} ${this.details.time}`).toLocaleTimeString('en-PH', option4),
            firstName: this.details.firstName,
            hour: 0,
            remainingMinute: this.details.lateMinute,
            type: this.details.type,
            isHoliday: this.isHoliday
          }
          console.log(request)
          const timeIn = await axios.post('Attendance/user/overTimeEnd', request, config)
          if (timeIn.status === 200) {
            this.$emit('msg', 'Ovetime added')
            this.$emit('success')
          }
        }
      } catch (e) {
        this.$emit('msg', 'failed to add attendance')
        this.$emit('error')
      }
    },
    async checkHoliday () {
      const apiKey = '33104524-6857-43f9-8ff3-6461db6fd9c9'
      const countryCode = 'PH'
      const year = 2022
      const month = (new Date(this.details.dateToday).getMonth() + 1).toString().padStart(2, '0')
      const day = new Date(this.details.dateToday).getDate().toString().padStart(2, '0')
      try {
        const url = `https://holidayapi.com/v1/holidays?key=${apiKey}&country=${countryCode}&year=${year}`
        const holiday = await axios.get(url)
        if (holiday.status === 200) {
          const holidayDates = holiday.data.holidays.map(e => e.date)
          const currentDate = `${year}-${month}-${day}`
          if (holidayDates.includes(currentDate)) {
            this.isHoliday = 'true'
          } else {
            this.isHoliday = 'false'
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    cancelAttendance () {
      this.exitModal()
    }
  },
  created () {
    this.handleDateToday()
    this.checkHoliday()
    this.Attendance()
  }
})
</script>
