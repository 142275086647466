<template>
    <div class="register-fingerprint-modal">
        <div id="modal-body">
            <div class="register-fingerprint-header">
                <h1>Register Employee Fingerprint</h1>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitFingerprint"/>
            </div>
            <div class="register-fingerprint-main">
                    <!--Register processing-->
                <div class="processing-register">
                    <img
                    class="fingerprint-img"
                    src="../assets/fingerprint.png"
                    alt="fingerprint">
                </div>
                <div class="process-msg">
                    <p>Registering...</p>
                    <p v-if="sucesss">SUCCESS!!!</p>
                    <p v-if="failed">FAILED!!!</p>
                </div>
            </div>
            <div class="register-fingerprint-footer">
                <button class="registerBtn" @click="registerBtn">Start</button>
                <button class="cancelBtn" @click="exitFingerprint">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import axios2 from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'RegisterFingerprint-Modal',
  data () {
    return {
      sucesss: false,
      failed: false
    }
  },
  props: {
    newUserId: Number
  },
  methods: {
    async registerBtn () {
      const cookieValue = sessionStorage.getItem('token')

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      // const api = axios.create({
      //   baseURL: 'http://192.168.0.29:7075',
      //   timeout: 50000,
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })
      const imgColor = document.querySelector('.fingerprint-img')
      const Uid = (this.newUserId + 4).toString()
      const id = { id: Uid }
      // const stringifyId = JSON.stringify(id)
      try {
        // const registerAsync = await api.post('/enroll', stringifyId)
        const registerAsync = await axios2.post('/fingerprint/enroll-fingerprint', id, config)

        if (registerAsync.status === 200) {
          imgColor.classList.add('success')
          this.sucesss = true
          console.log(id)
          this.$emit('fingerprintRegistered', id, 'success')
        } else {
          imgColor.classList.add('failed')
          this.failed = true
        }
      } catch (e) {
        if (e.status === 404 || e.status === 500) {
          imgColor.classList.add('failed')
          this.failed = true
        }
      }
    },
    exitFingerprint () {
      this.$emit('closeModal')
    }
  }
})
</script>
