<template>
    <div class="error-modal">
      <div id="modal-body">
        <header>
          <p id="change-detail-title">Error Modal</p>
          <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
        </header>
        <main>
          <h1>{{ message }}</h1>
        </main>
      </div>
    </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'error-modal',
  props: {
    message: String
  },
  methods: {
    exitModal () {
      this.$emit('exitModal')
    }
  }
})
</script>
