<template>
<div class="change-detail-modal">
    <div id="modal-body">
        <header>
            <p id="change-detail-title">Change Detail</p>
            <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
        </header>
        <main>
            <div id="firstname-div">
                <label for="firstName">First Name:</label>
                <input
                    type="text"
                    name="firstName"
                    id="firstName-mdl"
                    v-model="userData.firstName"
                    autocomplete="off"
                    />
            </div>
            <div id="lastname-div">
              <label for="middleName">Middle Name</label>
                      <input
                          name="middleName"
                          id="middleName-mdl"
                          v-model="userData.middleName"
                          type="text"
                          autocomplete="off"
                        />
            </div>
            <div id="lastname-div">
                <label for="lastName">Last Name:</label>
                <input
                    type="text"
                    name="lastName"
                    id="lastName-mdl"
                    v-model="userData.lastName"
                    autocomplete="off"
                    />
            </div>
            <div id="email-div">
                <label for="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    id="email-mdl"
                    v-model="userData.personalEmail"
                    autocomplete="off"
                    />
            </div>
            <div id="phoneNumber">
                <label for="phoneNumber">Phone Number:</label>
                <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber-mdl"
                    v-model="userData.PhoneNumber"
                    @input="formatPhoneNumber"
                    pattern="^\+\d{1,4}\s?\d{9}$"
                    autocomplete="off"
                    />
            </div>
        </main>
        <footer>
            <button @click="saveChanges" :disabled="!isChangesMade">Update Changes</button>
        </footer>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'change-detail-modal',
  data () {
    return {
      isChangesMade: false // Initialize to false
    }
  },
  computed: {
    formattedPhoneNumber: {
      get () {
        return this.formatInputPhoneNumber(this.details.phoneNumber)
      },
      set (value) {
        // Set the value as is, as it has already been formatted by the user
        this.details.phoneNumber = value
      }
    },
    ...mapState(['userData'])
  },
  watch: {
    userData: {
      deep: true, // Watch for changes in nested properties
      handler (newVal) {
        // Compare userData with the originalUserData
        this.isChangesMade = JSON.stringify(newVal) !== JSON.stringify(this.originalUserData)
      }
    }
  },
  created () {
    // Initialize originalUserData with a copy of userData
    this.originalUserData = { ...this.userData }
  },
  methods: {
    formatPhoneNumber () {
      // Format the input value while typing
      this.details.phoneNumber = this.formatInputPhoneNumber(this.details.phoneNumber)
    },
    formatInputPhoneNumber (value) {
      // Remove leading zeros and add +63 if it doesn't exist
      const formattedValue = value.replace(/^0/, '').trim()
      return formattedValue.startsWith('+63') ? formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3') : `+63 ${formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}`
    },
    saveChanges () {
      this.$store.commit('setChangeDetail', {
        firstName: this.userData.firstName,
        middleName: this.userData.middleName,
        lastName: this.userData.lastName,
        personalEmail: this.userData.personalEmail,
        PhoneNumber: this.userData.PhoneNumber
      })
      this.$emit('saveChanges')
      this.exitModal()
    },
    exitModal () {
      this.$emit('exitModal')
    }
  }
})
</script>
