<template>
<div class="employee-attendance-container">
  <div class="employee-header">
      <div class="employee-filter-option">
        <div class="filter-option1">
          <label for="type">Filter: </label>
          <select name="type" id="display-type" v-model="displayType">
            <option value="" selected>All</option>
            <option value="present">Present</option>
            <option value="break">break</option>
            <option value="absent">Absent</option>
            <option value="late">Late</option>
            <option value="out">Out</option>
            <option value="overtime">Overtime</option>
          </select>
        </div>
        <div class="filter-option2">
          <label for="calendar">Date: </label>
          <input
          id="calendar"
          name="calendar"
          type="date"
          v-model="date">
        </div>
      </div>
    </div>
  <div class="attendance-list">
    <table class="attendance-table">
          <tr id="title-row">
            <th class="border-radius-left">Status</th>
            <th>EMPLOYEE NAME</th>
            <th>DATE</th>
            <th>TIME-IN</th>
            <th>BREAK</th>
            <th>TIME-OUT</th>
            <th class="border-radius-right">OVERTIME</th>
          </tr>
          <!--loading-->
          <tr id="details-no-data" v-if="loading">
            <td colspan="7">
              <font-awesome-icon icon="spinner"/>
              Loading...
            </td>
          </tr>
          <!--without data-->
          <tr id="details-no-data" v-else-if="noData">
            <td colspan="7">
              <font-awesome-icon icon="inbox"/>
              No Data
            </td>
          </tr>
          <!--with data-->
          <tr id="details-row" v-else v-for="attendance in computedAttendance" :key="attendance.Id" :class="getTypeClass(attendance.type)">
            <td id="employeeStatus-row">
              <p>{{ attendance.type }}</p>
            </td>
            <td id="employeeName-row">
              <p>{{ attendance.firstName + ' ' + attendance.lastName }}</p>
            </td>
            <td id="date-row">
              <p>{{ attendance.date }}</p>
            </td>
            <td id="timeIn-row">
              <p>{{ attendance.timeIn || '--'}}</p>
            </td>
            <td id="breakTime-row">
              <p>{{ attendance.breakStart || attendance.breakEnd || '--'}}</p>
            </td>
            <td id="timeOut-row">
              <p>{{ attendance.timeOut || attendance.overTimeEnd || '--'}}</p>
            </td>
            <td id="overTime-row">
              <p>{{ attendance.overTimeStart || '--'}}</p>
            </td>
          </tr>
        </table>
      <div class="pagination">
        <PaginationComponent
          :totalPages="totalPages"
          :perPage="5"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          />
      </div>
  </div>
</div>
</template>

<script>
import axios from '@/api/axios'
import PaginationComponent from '../PaginationComponent.vue'
import { mapState } from 'vuex'
export default (await import('vue')).defineComponent({
  name: 'EmployeeAttendance',
  data () {
    return {
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 10,
      employeeAttendance: [],
      loading: true,
      noData: false,
      withData: false,
      date: '',
      displayType: ''
    }
  },
  watch: {
    // Watch for changes in the 'date' and 'filter' variable and call 'attendanceData' when it changes
    date: 'attendanceData',
    type: 'attendanceData'
  },
  computed: {
    computedAttendance () {
      /// Filter based on filterType and date
      let filteredAttendance = this.employeeAttendance

      if (this.displayType) {
        filteredAttendance = filteredAttendance.filter(attendance => attendance.type.includes(this.displayType))
      }

      if (this.date) {
        const selectedDate = new Date(this.date)

        const formattedDate = selectedDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
        filteredAttendance = filteredAttendance.filter(attendance => attendance.date === formattedDate)
      }

      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      this.totalItems = filteredAttendance.length
      // Return a slice of your data for the current page
      return filteredAttendance.slice(startIndex, endIndex)
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    },
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  components: {
    PaginationComponent
  },
  methods: {
    getTypeClass (type) {
    // Define your color coding logic based on the 'type' value
      if (type === 'present') {
        return 'present' // Replace 'present-class' with your actual class name
      } else if (type === 'absent') {
        return 'absent' // Replace 'absent-class' with your actual class name
      } else if (type.includes('late')) {
        return 'late' // Add more conditions as needed
      } else if (type === 'out') {
        return 'out'
      } else if (type === 'break') {
        return 'break'
      }
    },
    handleFilterChange (e) {
      this.withData = false
      this.loading = true
      this.employeeAttendance = []
    },
    onPageChange (page) {
      this.currentPage = page
    },
    empAttendanceData () {
      this.noData = true
    },
    async attendanceData () {
      try {
        const date = this.date

        const apiUrl = `/Attendance/records?search=${this.userData.firstName}&filter=${date}`
        const cookieValue = sessionStorage.getItem('token')
        const config = {
          headers: {
            Authorization: `${cookieValue}`
          }
        }
        const data = await axios.get(apiUrl, config)
        if (data.status === 200) {
          this.employeeAttendance = data.data
          this.$router.push(`/employee/attendance-module?&date=${date}`)
        }
        console.log(this.employeeAttendance)
        if (this.employeeAttendance.length === 0) {
          this.noData = true
          this.withData = false
        } else {
          this.noData = false
          this.withData = true
        }
      } catch (error) {
        console.error('Error fetching employee data:', error)
      }
    }
  },
  created () {
    // time out for a while to use loading effect and give space for data to be displayed
    this.attendanceData()
    setTimeout(() => {
      this.loading = false
      this.attendanceData()
    }, 2000)
  }
})
</script>
