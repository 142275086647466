<template>
    <div class="employee-div">
      <SuccessModal
        v-if="showSuccess"
        :message="msg"
        @exitSuccess="exitModal"/>
      <ErrorModal
        v-if="showError"
        :message="msg"
        @exitModal="exitModal"/>
        <form @submit="handleSubmit" id="employee-login-form">
            <div class="email-div">
              <label for="email"
                      id="emailLbl"><font-awesome-icon icon="envelope"/></label>
            <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                autocomplete="off"
                placeholder="Enter Employee Email..."
                required
                />
            </div>
            <div class="password-div">
              <label for="password"
                        id="passwordLbl"><font-awesome-icon icon="lock"/></label>
            <input
                  type="password"
                  name="password"
                  id="password"
                  class="password-input"
                  v-model="password"
                  placeholder="Enter Password..."
                  required
                />
                <font-awesome-icon icon="eye" v-if="show" @click="visible"/>
                <font-awesome-icon icon="eye-slash" v-if="hide" @click="hidden"/>
            </div>
            <button variant="sucess" class="loginBtn" :disabled="!email && password && handleSubmit" @click="handleSubmit">{{ loginBtn }}</button>
        </form>
    </div>
</template>

<script>
import ErrorModal from '@/modal/ErrorModal.vue'
import SuccessModal from '@/modal/SuccessModal.vue'
import axios from '@/api/axios'

import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default (await import('vue')).defineComponent({
  name: 'employeeLogin',
  components: {
    ErrorModal,
    SuccessModal
  },
  data () {
    return {
      show: false,
      hide: true,
      showSuccess: false,
      showError: false,
      msg: '',
      email: '',
      password: '',
      result: [],
      loginBtn: '',
      loginSuccess: false
    }
  },
  methods: {
    exitModal () {
      this.showError = false
      this.showSuccess = false
    },
    async handleSubmit (e) {
      e.preventDefault()

      this.loginBtn = '...'
      this.loginSuccess = true
      const authO = getAuth()
      const Email = this.email
      const Password = this.password

      try {
        const signIn = await signInWithEmailAndPassword(authO, Email, Password)
        if (signIn.user.emailVerified && !signIn.user.uid.includes('head') && !signIn.user.uid.includes('admin')) {
          let id
          if (signIn.user.email.includes('head')) {
            id = `head${signIn.user.uid}`
          } else {
            id = signIn.user.uid
          }

          const token = signIn._tokenResponse.idToken
          const email = signIn.user.email
          const photoURL = signIn.user.photoURL

          const modifiedURL = photoURL.replace('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images', '/images')

          const storage = getStorage()
          const forestRef = ref(storage, modifiedURL)

          try {
            const url = await getDownloadURL(forestRef)
            this.avatarURL = url // Set the URL to avatarURL if successful
          } catch (error) {
            console.error('Error fetching download URL:', error)
            this.avatarURL = null // Set avatarURL to null in case of error
          }
          const data = { token, id }

          const auth = await axios.post('/auth/login', data, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          })
          console.log(auth)
          if (auth.status === 200) {
            console.log(auth)
            const responseData = auth.data// Token Data
            const data = responseData

            const personalEmail = data.admins[0].personalEmail
            const firstName = data.admins[0].firstName
            const middleName = data.admins[0].middleName
            const lastName = data.admins[0].lastName
            const PhoneNumber = data.admins[0].phoneNumber
            const fingerId = data.admins[0].fingerId
            const role = data.admins[0].role
            const authData = { photoURL, id, email }
            this.$store.commit('setAuthData', {
              photoUrl: this.avatarURL,
              Uid: signIn.user.uid,
              email: authData.email,
              role: role
            })
            this.$store.commit('setUserData', {
              personalEmail,
              firstName,
              middleName,
              lastName,
              PhoneNumber,
              fingerId
            })
            sessionStorage.setItem('token', `${token}`)
            sessionStorage.setItem('role', 'employee')
            setTimeout(() => {
              this.$router.push('/employee/')
            }, 2000)
          }
        }
      } catch (e) {
        if (e.code === 'auth/wrong-password') {
          this.msg = 'Invalid Credentials!'
        } else {
          this.msg = 'Invalid Credentials!'
        }
        this.showError = true

        setTimeout(() => {
          this.exitModal()
          this.loginBtn = 'LOG - IN'
          this.loginSuccess = false
        }, 3000)
      }
    },
    visible () {
      const passwordInput = document.querySelector('.password-input')
      this.hide = !this.hide
      this.show = !this.show

      passwordInput.setAttribute('type', 'password')
    },
    hidden () {
      const passwordInput = document.querySelector('.password-input')
      this.show = !this.show
      this.hide = !this.hide

      passwordInput.setAttribute('type', 'text')
    }
  },
  created () {
    this.loginBtn = 'LOG - IN'
  }
})
</script>
