<template>
    <div class="payslip-div" @keydown.esc="$emit('exitPay')" tabindex="-1">
        <div id="modal-body">
            <header>
                <div class="corp-img-name">
                  <img src="../assets/received_202374629205091.webp" alt="wagyu-img" style="width: 100px; height: 100px;">
                  <p>WAGYUNIKU PAYSLIP</p>
                </div>
                <div class="emp-details">
                  <p>{{ 'Employee ID: ' + employeeData.id }}</p>
                  <p>{{ 'Employee Name: ' + employeeData?.firstName + ' ' +(employeeData?.middlename ?? '') + ' ' +employeeData?.lastName}}</p>
                  <p>{{ 'Cuttoff Date: ' + employeeData?.startDate + '-' + employeeData?.endDate}}</p>
                </div>
            </header>
            <main>
                <div class="computation-container">
                  <div class="wage">
                    <p>WAGE (per hr): </p>
                    <p>{{ 'Php: ' + employeeData?.wage }}</p>
                  </div>
                  <div class="bonus">
                    <p>Bonus: </p>
                    <p>{{ 'Php: ' + (employeeData?.bonus ?? 0) }}</p>
                  </div>
                  <div class="hour">
                      <p>TOTAL HOURS: </p>
                      <p>{{ (employeeData?.hour ?? 0) + ':' + (employeeData?.remainingMinute ?? 0) }}</p>
                  </div>
                  <div class="late">
                    <div class="late-mins">
                      <p>OVERTIME HOUR: </p>
                      <p>{{ (employeeData?.overTimeHour ?? 0) + ':' + (employeeData?.overTimeMinute  ?? 0) }}</p>
                    </div>
                    <div class="late-hour">
                      <p>Late: </p>
                      <p>{{ (employeeData?.lateHour ?? 0 )+ ':' + (employeeData?.lateMinute ?? 0) }}</p>
                    </div>
                  </div>
                  <div class="philhealth">
                    <p>PhilHealth: </p>
                    <p>{{ 'Php: ' + employeeData?.philhealth || 0}}</p>
                  </div>
                  <div class="sss">
                    <p>SSS: </p>
                    <p>{{ 'Php: ' + employeeData?.sss || 0}}</p>
                  </div>
                  <div class="pagibig">
                    <p>Pag-Ibig: </p>
                    <p>{{ 'Php: ' + employeeData?.pagibig || 0 }}</p>
                  </div>
                  <div class="deductions">
                    <p>Deduction: </p>
                    <p>{{ 'Php: ' + employeeData?.deductions || 0 }}</p>
                  </div>
                </div>
            </main>
            <footer>
              <p>GROSSPAY: </p>
              <p>{{ 'Php: ' + employeeData?.grossEarnings || 0.00 }}</p>
            </footer>
        </div>
    </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'payslip-template',
  props: {
    employeeData: Object
  },
  mounted () {
    this.$el.focus()
    console.log(this.employeeData)
    setTimeout(() => {
      window.print()
    }, 1000)
  }
})
</script>
