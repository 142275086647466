<template>
    <div class="employee-sched-div">
      <div class="employee-header">
        <label for="day-options">Day Filter: </label>
        <select name="day-option" id="day-options" v-model="day">
          <option value="" selected>All week</option>
          <option value="SUN">Sunday</option>
          <option value="M">Monday</option>
          <option value="T">Tuesday</option>
          <option value="W">Wednesday</option>
          <option value="TH">Thursday</option>
          <option value="F">Friday</option>
          <option value="SAT">Saturday</option>
        </select>
      </div>
      <div class="employee-sched-container">
        <ErrorModal
        v-if="showError"
        :message="msg"
        @exitModal="closeError"
        />
        <table class="sched-table">
          <tr id="sched-title">
            <th>SHIFT</th>
            <th>DAYS</th>
            <th>TIME</th>
            <th>BREAK</th>
            <th>OUT</th>
            <th>STATION</th>
          </tr>
          <!--loading-->
          <tr id="sched-data" v-if="loading">
            <td colspan="6">
              <font-awesome-icon icon="spinner"/>
              Loading...
            </td>
          </tr>
          <!--noData-->
          <tr id="sched-data" v-else-if="isDayOff">
            <td colspan="6">
              <font-awesome-icon icon="calendar-xmark"/>
              Off duty
            </td>
          </tr>
          <tr id="sched-data" v-else v-for="sched in employeeSched" :key="sched?.id">
            <td id="sched-shift">
              <p>{{ sched.title }}</p>
            </td>
            <td id="sched-days">
              <p>{{ formatDays(sched.days) }}</p>
            </td>
            <td id="sched-time">
              <p>{{ formatTimeAndDate(sched.startTime, sched.startDate) }}</p>
            </td>
            <td id="sched-break">
              <p>{{ sched.break }}</p>
            </td>
            <td id="sched-out">
              <p>{{ formatTimeAndDate(sched.endTime, sched.endDate) }}</p>
            </td>
            <td id="sched-station">
              <p>{{ station }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import ErrorModal from '@/modal/ErrorModal.vue'
import { mapState } from 'vuex'
export default (await import('vue')).defineComponent({
  name: 'Employee-Sched',
  components: {
    ErrorModal
  },
  data () {
    return {
      day: '',
      dayVal: '',
      employeeSched: [],
      loading: true,
      isDayOff: false,
      attend: false,
      station: '',
      msg: '',
      showError: false
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  watch: {
    day (val) {
      this.loading = true
      this.dayVal = val
      this.getEmpSched()
    }
  },
  methods: {
    async getEmpSched () {
      const cookieValue = sessionStorage.getItem('token')
      const filter = this.dayVal
      const role = this.authData.role

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      const params = {
        id: this.authData.Uid
      }

      const apiUrl = `/employee/user/sched?filter=${filter}`
      try {
        const sched = await axios.get(apiUrl, { params, ...config })
        if (sched.data.schedules.length > 0) {
          if (sched.data.schedules[0].title != null) {
            this.loading = false
            if (role === 'barista' || role === 'cashier' || role === 'restaurant manager' ||
              role === 'server' || role === 'security guard') {
              this.station = 'Front of the House'
            } else {
              this.loading = false
              this.station = 'Back of the House'
            }
            if (filter !== '') {
              this.loading = true
              this.employeeSched = sched.data.schedules.filter((schedule) =>
                Array.isArray(schedule.days) && schedule.days.includes(filter)
              )
              this.loading = false
            } else {
              this.employeeSched = sched.data.schedules
            }
            if (this.employeeSched.length === 0) {
              this.loading = false
              this.isDayOff = true
            } else {
              this.loading = false
              this.isDayOff = false
            }
          }
        } else {
          this.loading = false
          this.isDayOff = true
        }
      } catch (e) {
        console.log(e)
        this.msg = 'Error Occur'
        this.showError = true
      }
    },
    closeError () {
      this.showError = false
    },
    formatDays (daysArray) {
      if (daysArray) {
        return daysArray.join(', ')
      }
    },
    formatTimeAndDate (startTime, startDate) {
      // Parse startDate and startTime into Date objects
      const startDateObject = new Date(startDate)
      const [hours, minutes] = startTime.split(':')

      // Set the time components of the startDateObject
      startDateObject.setHours(parseInt(hours, 10))
      startDateObject.setMinutes(parseInt(minutes, 10))

      // Define options for formatting
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Manila'
      }

      // Format and return the combined date and time
      return startDateObject.toLocaleTimeString('en-US', options)
    }

  },
  created () {
    this.getEmpSched()
  }
})
</script>
