<template>
    <div class="sidebar-div">
      <div class="logo-exit-div">
        <div class="wagyuniku-img">
          <a href="/admin/"><img class="sideLogo" src="../../assets/received_202374629205091.webp" alt="wagyuniku"/></a>
        </div>
        <div class="exit">
          <i class="exitBtn"><font-awesome-icon icon="xmark" @click="exitSidebar"/></i>
        </div>
      </div>
      <div class="tabs-list-div">
          <ul class="tabs-unsortedList">
            <button class="attendance-dropdown-btn tabs-li" id="attendanceBtn" @click="attendanceDropBtn">
              <font-awesome-icon icon="user-check" />
              <p> Attendance</p>
              <font-awesome-icon icon="caret-down"  class="drop-icon" :class="{ active: isActive }"/>
            </button>
            <ul class="attendance-tabs-div" :class="{ active: isActive }">
              <a class="tabs-li" href="/admin/attendance-module">
                <font-awesome-icon icon="building-user" />
                <p> Attendance Module</p>
              </a>
              <a class="tabs-li" href="/admin/attendance-report">
                <font-awesome-icon icon="clipboard-user" />
                <p> Attendance Report</p>
              </a>
            </ul>
            <a class="tabs-li" href="/admin/schedule-module">
              <font-awesome-icon icon="calendar" />
              <p> Schedule Module</p>
            </a>
            <button class="payroll-dropdown-btn tabs-li" id="payrollBtn" @click="payrollDropBtn">
              <font-awesome-icon icon="money-bill" />
              <p> Payroll</p>
              <font-awesome-icon icon="caret-down"  class="drop-icon" :class="{ active: isActive1 }"/>
            </button>
            <ul class="payroll-tabs-div" :class="{ active: isActive1 }">
              <a class="tabs-li" href="/admin/payroll-module">
                <font-awesome-icon icon="money-bill-wave" />
                <p> Payroll Module</p>
              </a>
              <a class="tabs-li" href="/admin/payroll/history">
                <font-awesome-icon icon="money-bill-wave" />
                <p> Payroll history</p>
              </a>
            </ul>
            <a class="tabs-li" href="/admin/employee-list">
              <font-awesome-icon icon="users" />
              <p> Employee List</p>
            </a>
          </ul>
      </div>
    </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'AdminSidebar',
  data () {
    return {
      sidebar: false,
      isActive: false,
      isActive1: false
    }
  },
  methods: {
    attendanceDropBtn () {
      this.isActive = !this.isActive
      if (this.isActive1 === true) {
        this.isActive1 = !this.isActive1
      }
    },
    payrollDropBtn () {
      this.isActive1 = !this.isActive1
      if (this.isActive === true) {
        this.isActive = !this.isActive
      }
    },
    exitSidebar () {
      const sidebarDiv = document.querySelector('.sidebar-div')
      this.sidebar = !this.sidebar
      sidebarDiv.classList.remove('active')
    }
  }
})

</script>
