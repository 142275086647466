<template>
    <div class="admin-div">
      <div class="msg-box" v-if="msgShow">
        <font-awesome-icon class="exit-msg" icon="xmark" @click="exitMsg"/>
        <p class="msg-txt"></p>
      </div>
        <form id="admin-login-form">
            <div class="email-div">
              <label for="email"
                      id="emailLbl"><font-awesome-icon icon="envelope"/></label>
              <input
                  type="email"
                  name="email"
                  v-model="email"
                  autocomplete="off"
                  placeholder="Enter Admin Email..."
                  required
                  />
            </div>
            <div class="password-div">
              <label for="password"
                      id="passwordLbl"><font-awesome-icon icon="lock"/></label>
              <input
                    type="password"
                    name="password"
                    class="password-input"
                    v-model="password"
                    placeholder="Enter Password..."
                    required
                  />
                  <font-awesome-icon icon="eye" v-if="show" @click="visible"/>
                  <font-awesome-icon icon="eye-slash" v-if="hide" @click="hidden"/>
            </div>
            <button class="loginBtn" :disabled="!email.includes('@wagyuniku.com') || !password" @click="handleLogin">{{ logInBtn }}</button>
        </form>
    </div>
</template>

<script>
import axios from '@/api/axios'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default (await import('vue')).defineComponent({
  name: 'adminLogin',
  data () {
    return {
      show: false,
      hide: true,
      msgShow: false,
      email: '',
      password: '',
      result: [],
      avatarURL: null,
      logInBtn: '',
      loginSuccess: false
    }
  },
  methods: {
    async handleLogin (e) {
      e.preventDefault()

      // Get the current timestamp for lastLoggedIn
      // const dateToday = new Date()
      // const miliseconds = dateToday.getTime()
      // const timestamp = miliseconds / 1000
      // const lastLoggedIn = timestamp.toString()

      this.logInBtn = '...'
      this.logInSuccess = true
      const authO = getAuth()
      const Email = this.email
      const Password = this.password

      try {
        const signIn = await signInWithEmailAndPassword(authO, Email, Password)

        if (signIn.user.emailVerified) {
          if (!signIn.user.email.includes('employee')) {
            let id
            if (signIn.user.email.includes('head')) {
              id = `head${signIn.user.uid}`
            } else {
              id = signIn.user.uid
            }
            const token = signIn._tokenResponse.idToken
            const email = signIn.user.email
            const photoURL = signIn.user.photoURL

            const modifiedURL = photoURL.replace('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images', '/images')

            const storage = getStorage()
            const forestRef = ref(storage, modifiedURL)
            try {
              const url = await getDownloadURL(forestRef)
              this.avatarURL = url // Set the URL to avatarURL if successful
            } catch (error) {
              console.error('Error fetching download URL:', error)
              this.avatarURL = null // Set avatarURL to null in case of error
            }
            const data = { token, id }

            const auth = await axios.post('/auth/login', data, {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            })
            if (auth.status === 200) {
              const responseData = auth.data// Token Data
              const data = responseData

              const personalEmail = data.admins[0].personalEmail
              const firstName = data.admins[0].firstName
              const middleName = data.admins[0].middleName
              const lastName = data.admins[0].lastName
              const PhoneNumber = data.admins[0].phoneNumber
              const authData = { photoURL, id, email }
              this.$store.commit('setAuthData', {
                photoUrl: this.avatarURL,
                Uid: signIn.user.uid,
                email: authData.email
              })
              this.$store.commit('setUserData', {
                personalEmail,
                firstName,
                middleName,
                lastName,
                PhoneNumber
              })
              sessionStorage.setItem('token', `${token}`)
              sessionStorage.setItem('role', 'admin')
              setTimeout(() => {
                this.$router.push('/admin/')
              }, 2000)
            }
          } else {
            this.msgShow = !this.msgShow
            this.$nextTick(() => {
              const msg = document.querySelector('.msg-txt')
              if (msg) {
                msg.textContent = 'Please Log in as Employee'
              }
            })
            this.logInBtn = 'LOG - IN'
            this.logInSuccess = false
          }
        } else {
          this.msgShow = !this.msgShow
          this.$nextTick(() => {
            const msg = document.querySelector('.msg-txt')
            if (msg) {
              msg.textContent = 'Please Log in as Employee'
            }
          })
          this.logInBtn = 'LOG - IN'
          this.logInSuccess = false
        }
      } catch (e) {
        this.msgShow = !this.msgShow

        // Function to set error message
        const setErrorMessage = (message) => {
          this.$nextTick(() => {
            const msg = document.querySelector('.msg-txt')
            if (msg) {
              msg.textContent = message
            }
          })
        }

        if (e.code === 'auth/wrong-password') {
          setErrorMessage('Invalid Credentials!')
        } else {
          setErrorMessage('Invalid Credentials!')
        }

        setTimeout(() => {
          this.msgShow = !this.msgShow
          this.logInBtn = 'LOG - IN'
          this.loginSuccess = false
        }, 3000)
      }
    },
    visible () {
      const passwordInput = document.querySelector('.password-input')
      this.hide = !this.hide
      this.show = !this.show

      passwordInput.setAttribute('type', 'password')
    },
    hidden () {
      const passwordInput = document.querySelector('.password-input')
      this.show = !this.show
      this.hide = !this.hide

      passwordInput.setAttribute('type', 'text')
    },
    exitMsg () {
      this.msgShow = !this.msgShow
    }
  },
  created () {
    this.logInBtn = 'LOG - IN'
  }
})
</script>
