<template>
  <div class="attendace-module-div">
    <AttendanceModal
    v-if="showModal"
    @success="successResult"
    @error="errorResult"
    @msg="messages"
    @close="closeModal"
    />
    <SuccessModal
      v-if="showSuccess"
      :message="msg"
      @exitSuccess="closeModal"
    />
    <ErrorModal
      v-if="showError"
      :message="msg"
      @exitModal="closeModal"
    />
    <div class="header">
      <div class="search-div">
        <input
        type="text"
        placeholder="search"
        name="search"
        id="search"
        v-model="searchQuery"
        autocomplete="off">
        <font-awesome-icon icon="magnifying-glass" class="searchBtn" @click="searchBtn"/>
      </div>
      <div class="head-access">
        <button :disabled="!role.email.includes('head')" @click="showAdd">ADD ATTENDANCE</button>
      </div>
      <div class="filter-option">
          <label for="calendar">Filter: </label>
          <input
          id="calendar"
          name="calendar"
          type="date"
          v-model="date">
      </div>
    </div>
    <div class="attendance-list">
      <div class="table-container">
        <table class="attendance-table">
        <tr id="title-row">
          <th class="border-radius-left">STATUS</th>
          <th>EMPLOYEE NAME</th>
          <th>DATE</th>
          <th>TIME-IN</th>
          <th>BREAK</th>
          <th>TIME-OUT</th>
          <th class="border-radius-right">OVERTIME</th>
        </tr>
        <!--loading-->
        <tr id="details-no-data" v-if="loading">
          <td colspan="7">
            <font-awesome-icon icon="spinner"/>
            Loading...
          </td>
        </tr>
        <!--without data-->
        <tr id="details-no-data" v-else-if="noData">
          <td colspan="7">
            <font-awesome-icon icon="inbox"/>
            No Data
          </td>
        </tr>
        <!--with data-->
        <tr id="details-row" v-else v-for="attendance in computedAttendance" :key="attendance.Id" :class="getTypeClass(attendance.type)">
          <td id="employeeStatus-row">
            <p>{{ attendance.type }}</p>
          </td>
          <td id="employeeName-row">
            <p>{{ attendance.firstName + ' ' + attendance.lastName }}</p>
          </td>
          <td id="date-row">
            <p>{{ attendance.date }}</p>
          </td>
          <td id="timeIn-row">
            <p>{{ attendance.timeIn || '--'}}</p>
          </td>
          <td id="breakTime-row">
            <p>{{ attendance.breakStart || attendance.breakEnd || '--'}}</p>
          </td>
          <td id="timeOut-row">
            <p>{{ attendance.timeOut || '--'}}</p>
          </td>
          <td id="overTime-row">
            <p>{{ attendance.overTimeStart || attendance.overTimeEnd || '--'}}</p>
          </td>
        </tr>
      </table>
      </div>
      <div class="pagination">
        <PaginationComponent
          :totalPages="totalPages"
          :perPage="5"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue'
import AttendanceModal from '@/modal/AttendanceModal.vue'
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'

import axios from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'AdminAttendance',
  components: {
    PaginationComponent,
    AttendanceModal,
    SuccessModal,
    ErrorModal
  },
  data () {
    return {
      currentPage: 1,
      totalItems: 0, // Total number of items
      itemsPerPage: 12,
      attendance: [],
      loading: true,
      noData: false,
      withData: false,
      searchQuery: '',
      date: '',
      showModal: false,
      showSuccess: false,
      showError: false,
      role: JSON.parse(sessionStorage.getItem('auth-data')),
      msg: ''
    }
  },
  watch: {
    date: 'attendanceData' // Watch for changes in the 'date' variable and call 'attendanceData' when it changes
  },
  computed: {
    computedAttendance () {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Return a slice of your data for the current page
      return this.attendance.slice(startIndex, endIndex)
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    }
  },
  methods: {
    getTypeClass (type) {
    // Define your color coding logic based on the 'type' value
      if (type === 'present') {
        return 'present' // Replace 'present-class' with your actual class name
      } else if (type === 'absent') {
        return 'absent' // Replace 'absent-class' with your actual class name
      } else if (type.includes('late')) {
        return 'late' // Add more conditions as needed
      } else if (type === 'out') {
        return 'out'
      } else if (type === 'break') {
        return 'break'
      }
    },
    searchBtn (e) {
      e.preventDefault()
      this.withData = false
      this.loading = true
      this.attendance = []
      setTimeout(() => {
        this.attendanceData()
        this.loading = false
      }, 2000)
    },
    onPageChange (page) {
      this.currentPage = page
    },
    async attendanceData () {
      try {
        const search = this.searchQuery
        const date = this.date

        const apiUrl = `/Attendance/records?search=${search}&filter=${date}`
        const cookieValue = sessionStorage.getItem('token')
        const config = {
          headers: {
            Authorization: `${cookieValue}`
          }
        }
        const data = await axios.get(apiUrl, config)

        if (data.status === 200) {
          this.attendance = data.data
          this.totalItems = this.attendance.length
          this.$router.push(`/admin/attendance-module?search=${search}&date=${date}`)
        }
        if (this.attendance.length === 0) {
          this.noData = true
          this.withData = false
        } else {
          this.noData = false
          this.withData = true
        }
      } catch (error) {
        console.error('Error fetching employee data:', error)
      }
    },
    showAdd () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
      this.showError = false
      this.showSuccess = false
    },
    successModal () {
      this.showSuccess = true
    },
    ErrorRes () {
      this.showError = true
    },
    messages (val) {
      this.msg = val
    },
    successResult () {
      this.closeModal()
      this.successModal()
      setTimeout(() => {
        this.loading = false
        this.attendanceData()
      }, 2000)
    },
    errorResult () {
      this.closeModal()
      this.showError()
      setTimeout(() => {
        this.loading = false
        this.attendanceData()
      }, 2000)
    }
  },
  created () {
    // Fetch initial attendance data when the component is created
    setTimeout(() => {
      this.loading = false
      this.attendanceData()
    }, 2000)

    // Interval for fetching data.
    setInterval(this.attendanceData, 100000)
  }
})
</script>
