<template>
<div class="user-settings-div">
 <ul class="settings-div">
    <li class="acc-sett" @click="accountSettings">
      <a href="/admin/user-settings" v-if="isAdmin">Account Settings</a>
      <a href="/employee/user-settings" v-if="isEmployee">Account Settings</a>
    </li>
    <li class="log-out">
      <a @click="logOutClicked">Log Out</a>
    </li>
 </ul>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { getAuth, signOut } from 'firebase/auth'
import axios from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'Profile-settings',
  data () {
    return {
      show: false,
      isAdmin: false,
      isEmployee: false
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  methods: {
    async logOutClicked () {
      const Email = this.userData.personalEmail
      const autEmail = this.authData.email
      let type = ''
      if (autEmail.includes('head')) {
        console.log('head')
        type = 'headAdmin'
      } else if (autEmail.includes('employee')) {
        console.log('employee')
        type = 'employee'
      } else {
        type = 'admin'
      }
      console.log(Email)
      const authO = getAuth()
      const auth = sessionStorage.getItem('token')
      try {
        const data = { Email, type }
        const response = await axios.put('/auth/logOut', data,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${auth}`
            }
          })
        // if it is success we will clear session then re-route user to login
        if (response.status === 200 || response.status === 204) {
          this.show = !this.show
          await signOut(authO)
          sessionStorage.clear()
          this.$router.push('/')
        } else {
          // if server error will just return
          return
        }
      } catch (e) {
        alert(e.message)
        this.show = !this.show
      }
    },
    accountSettings () {
      this.show = !this.show
    }
  },
  created () {
    const role = sessionStorage.getItem('role')

    if (role === 'admin') {
      this.isEmployee = false
      this.isAdmin = true
    } else {
      this.isAdmin = false
      this.isEmployee = true
    }
  }
})
</script>
