<template>
    <div class="payroll-history-div">
        <div class="payroll-header">
            <div class="search-div">
                <input
                    type="search"
                    placeholder="search"
                    name="search"
                    id="search"
                    v-model="searchQuery"
                    autocomplete="off">
                <font-awesome-icon icon="magnifying-glass" class="searchBtn"/>
            </div>
            <div class="filter-options">
                <div class="grossEarning">
                    <label for="bygross"> By gross pay:</label>
                    <select name="bygross" id="bygross" v-model="gross">
                    <option value="" selected>Default</option>
                    <option value="up">high to low</option>
                    <option value="low">low to high</option>
                    </select>
                </div>
                <div class="cuttoff-filter">
                    <label for="date">Cutt off:</label>
                    <select name="date" id="bygross" v-model="filter">
                        <option value="" selected>All</option>
                        <option v-for="cutoffDate in dates" :key="cutoffDate.payId" :value="cutoffDate.payId">
                        {{ cutoffDate.payId }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="pay-content-div">
            <div id="data-title">
                <p>EMPLOYEE NAME</p>
                <p>CUTOFF DATE</p>
                <p>GROSS PAY</p>
                <p>DETAILS</p>
            </div>
            <div id="payroll-data" v-if="isloading">
                <font-awesome-icon icon="spinner"/>
                <p>Loading</p>
            </div>
            <div id="payroll-data" v-if="noData">
                <font-awesome-icon icon="inbox"/>
                <p>No Data</p>
            </div>
            <div id="payroll-data" v-else v-for="data in computedPayroll" :key="data.id">
                <p>{{ data.firstName }}</p>
                <p>{{ data.startDate + '-' + data.endDate }}</p>
                <p>{{ data.grossEarnings  || 0.00 }}</p>
                <button class="show-info" @click="showPayrollInfo(data.id)">
                  <font-awesome-icon icon="receipt" class="searchBtn"/>
                </button>
            </div>
        </div>
            <div class="pay-footer-div">
                <PaginationComponent
                :totalPages="totalPages"
                :perPage="5"
                :currentPage="currentPage"
                @pagechanged="onPageChange"
                />
            </div>
        </div>
</template>

<script>
import axios from '@/api/axios'
import PaginationComponent from '@/components/PaginationComponent.vue'
export default (await import('vue')).defineComponent({
  name: 'payrollHistory',
  data () {
    return {
      payroll: [],
      dates: [],
      isloading: true,
      noData: false,
      filter: '',
      gross: '',
      searchQuery: '',
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 5
    }
  },
  components: {
    PaginationComponent
  },
  computed: {
    computedPayroll () {
      const search = this.searchQuery.trim().toLowerCase()
      const bygross = this.gross
      const filter = this.filter
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Check if the search query is not empty
      if (search.length > 0) {
        // Use the filter method to create a new array based on the search query
        const filteredPayroll = this.payroll.filter(
          (e) =>
            e.firstName.toLowerCase().includes(search) ||
        e.lastName.toLowerCase().includes(search)
        )

        const sortedPayroll = this.sortByGross(filteredPayroll, bygross)
        this.totalItems = sortedPayroll.length
        return sortedPayroll.slice(startIndex, endIndex)
      } if (filter.length > 0) {
        // Use the filter method to create a new array based on the search query
        const filteredPayroll = this.payroll.filter(
          (e) =>
            e.payId.includes(filter)
        )

        const sortedPayroll = this.sortByGross(filteredPayroll, bygross)
        this.totalItems = sortedPayroll.length
        return sortedPayroll.slice(startIndex, endIndex)
      } else {
        // If search query is empty, return the original payroll data
        const sortedPayroll = this.sortByGross(this.payroll, bygross)
        this.totalItems = sortedPayroll.length
        return sortedPayroll.slice(startIndex, endIndex)
      }
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    }
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
    },
    async getPayroll () {
      const params = { filter: this.filter }
      const cookieValue = sessionStorage.getItem('token')

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const allHistory = await axios.get('/payroll/payroll-history', { params, ...config })
        const uniquePayIds = new Set()
        if (allHistory.status === 200) {
          this.payroll = allHistory.data
          this.dates = allHistory.data.filter((entry) => {
            // Check if payId already exists in the set
            if (!uniquePayIds.has(entry.payId)) {
              // If not, add it to the set and return true to keep it in the filtered array
              uniquePayIds.add(entry.payId)
              return true
            }
            // If payId already exists, return false to filter it out
            return false
          })
        }

        if (allHistory.data.length === 0) {
          this.isloading = false
          this.noData = true
        } else {
          this.isloading = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    sortByGross (data, bygross) {
      if (bygross === 'up') {
        // Sort data in ascending order based on grossEarnings
        return data.slice().sort((a, b) => b.grossEarnings - a.grossEarnings)
      } else if (bygross === 'low') {
        // Sort data in descending order based on grossEarnings
        return data.slice().sort((a, b) => a.grossEarnings - b.grossEarnings)
      } else {
        // Default: return data as is
        return data
      }
    },
    showPayrollInfo (id) {
      this.$router.push(`user-history/${id}`)
    }
  },
  created () {
    // Fetch initial payroll data when the component is created
    this.getPayroll()
  }
})
</script>
