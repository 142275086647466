<template>
    <div class="employee-pay-div">
      <div class="payroll-view-header">
        <div class="filter">
          <div id="cutoff-selection">
            <label for="cutoff-option">History Date: </label>
            <select name="cutoff-option" id="cutoff-option" v-model="cutoff">
              <option value="" selected>all</option>
              <option v-for="data in months" :value="data.payId" :key="data.dateAsId">
              {{ data.payId }}
              </option>
            </select>
          </div>
        </div>
        <div class="salary-total">TOTAL SALARY: {{ salaryTotal || 0.00}} Php</div>
      </div>
      <div class="payroll-view-main">
        <div class="breakdown-title">
          <p>Salary Computation</p>
          <p>Cuttoff date:
            {{ salaryArray[0]?.startDate + '-' + salaryArray[0]?.endDate }}
          </p>
        </div>
        <!--loading-->
        <div id="loading" v-if="loading">
          <font-awesome-icon icon="spinner"/>
          <p>Loading</p>
        </div>
        <!--no data-->
        <div id="salary-noData" v-else-if="noData">
          <font-awesome-icon icon="inbox"/>
          <p>No Data</p>
        </div>
        <!--with data-->
        <div id="salary-breakdown" v-else v-for="data in salaryArray" :key="data.id">
          <div id="breakdown-amount">
            <div class="pay-per-hour">
              <p>Pay per-hour</p>
              <p>{{ 'PhP '+ data.wage ||'PhP '+ 0}}</p>
            </div>
            <div class="total-hours">
              <p>Total hours worked</p>
              <p>{{ (data.hour ?? 0) + ':' + (data.remainingMinute ?? 0)}}</p>
            </div>
            <div class="overtime-hours">
              <p>Overtime</p>
              <p>{{ (data.overTimeHour ?? 0)+ ':' +(data.overTimeMinute ?? 0)}}</p>
            </div>
            <div class="sss">
              <p>SSS</p>
              <p>{{ 'PhP '+ data.sss ||'PhP '+ 0}}</p>
            </div>
            <div class="philhealth">
              <p>Philhealth</p>
              <p>{{ 'PhP '+ data.philhealth ||'PhP '+ 0}}</p>
            </div>
            <div class="pagibig">
              <p>Pag-Ibig</p>
              <p>{{ 'PhP '+ data.pagibig ||'PhP '+ 0}}</p>
            </div>
            <div class="deductions">
              <p>Deduction</p>
              <p>{{ 'PhP '+ data.deductions ||'PhP '+ 0}}</p>
            </div>
            <div class="gross-pay">
              <p>GROSS-PAY</p>
              <p>{{'PhP'+ data.grossEarnings || 0}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="payroll-history">
        <div class="history-title">
          <p>Payroll History</p>
        </div>
        <div class="title">
          <p>DATE</p>
          <p>GROSS PAY</p>
        </div>
        <!--loading-->
        <div id="loading" v-if="loading2">
          <font-awesome-icon icon="spinner"/>
          <p>Loading</p>
        </div>
        <!--no data-->
        <div id="salary-noData" v-else-if="noData2">
          <font-awesome-icon icon="inbox"/>
          <p>No Data</p>
        </div>
        <!--with data-->
        <div id="payroll-history-list" v-else v-for="data in computedPayrollHistory" :key="data.id">
          <div class="payroll-date">
            <p>{{ data.payId }}</p>
            <p>{{ data.grossEarnings + ' Php' || 0 + ' Php'}}</p>
          </div>
        </div>
        <div class="pagination">
          <PaginationComponent
            :totalPages="totalPages"
            :perPage="itemsPerPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
            />
        </div>
      </div>
    </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue'
import axios from '@/api/axios'
import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'EmployeePayroll',
  data () {
    return {
      cutoff: '',
      months: [],
      salaryTotal: null,
      salaryArray: [],
      salaryHistory: [],
      loading: true,
      loading2: true,
      noData2: false,
      noData: false,
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 5
    }
  },
  components: {
    PaginationComponent
  },
  computed: {
    computedPayrollHistory () {
      const filter = this.cutoff
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      if (filter.length > 0) {
        // Use the filter method to create a new array based on the search query
        const filteredPayroll = this.salaryHistory.filter(
          (e) =>
            e.payId.includes(filter)
        )
        this.salaryArray = filteredPayroll
        this.salaryTotal = filteredPayroll[0].grossEarnings
        this.totalItems = filteredPayroll.length
        return filteredPayroll.slice(startIndex, endIndex)
      } else {
        // If search query is empty, return the original payroll data
        this.getPayroll()
        this.totalItems = this.salaryHistory.length
        return this.salaryHistory.slice(startIndex, endIndex)
      }
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    },
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
    },
    async getPayroll () {
      const params = { id: this.authData.Uid }
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      try {
        const payroll = await axios.get('/payroll/user-payroll', { params, ...config })
        if (payroll.status === 200) {
          this.salaryArray = payroll.data
          this.salaryTotal = payroll.data[0].grossEarnings
        }
        if (this.salaryArray.length === 0) {
          this.loading = false
          this.noData = true
        } else {
          this.loading = false
          this.noData = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getPayrollHistory () {
      const params = { id: this.authData.Uid }
      const token = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      try {
        const userPayroll = await axios.get('/payroll/user/payroll-history', { params, ...config })
        const uniquePayIds = new Set()
        if (userPayroll.status === 200) {
          this.salaryHistory = userPayroll.data
          this.months = userPayroll.data.filter((entry) => {
            // Check if payId already exists in the set
            if (!uniquePayIds.has(entry.payId)) {
              // If not, add it to the set and return true to keep it in the filtered array
              uniquePayIds.add(entry.payId)
              return true
            }
            // If payId already exists, return false to filter it out
            return false
          })
        }
        if (this.salaryHistory.length > 0) {
          this.loading2 = false
          this.noData2 = false
        } else {
          this.loading2 = false
          this.noData2 = true
        }
      } catch (e) {
        this.loading2 = false
        this.noData2 = false
      }
    }
  },
  mounted () {
    this.getPayroll()
    this.getPayrollHistory()
  }
})
</script>
