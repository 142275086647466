<template>
    <div class="reAuth-modal">
        <div id="modal-body">
            <header>
                <p id="reAuthenticate-title">Admin Permission</p>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="cancel"/>
            </header>
            <main>
                <div id="old-password-div">
                    <label for="old-password-input">Password: </label>
                    <input
                        type="password"
                        v-model="password"
                        placeholder="password..."
                        autocomplete="off"
                    />
                </div>
            </main>
            <footer>
                <button @click="reLogin"
                        class="changePassSubmit"
                        :disabled="!password">
                        success
                </button>
                <button @click="cancel"
                        class="changePassCancel">
                        cancel
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth'

export default (await import('vue')).defineComponent({
  name: 'reAuthenticate-modal',
  data () {
    return {
      password: ''
    }
  },
  methods: {
    async reLogin () {
      try {
        const signIn = await signInWithEmailAndPassword('head.admin03@wagyuniku.com', this.password)
        if (signIn.user) {
          this.$emit('handleApprove', 'yes')
          this.cancel()
        } else {
          this.$emit('handleApprove', 'no')
          this.cancel()
        }
      } catch (e) {
        console.log(e)
      }
    },
    cancel () {
      this.$emit('close')
    }
  }
})
</script>
