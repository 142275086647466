<template>
 <div class="employee-list-div">
    <div class="empList-nav-div">
      <div id="search-div">
         <input
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            v-model="searchQuery"
            autocomplete="off">
            <font-awesome-icon icon="magnifying-glass"
            class="searchBtn"
            @click="searchBtn"
            />
      </div>
      <div class="create-filter-container">
        <div class="create-container">
         <button id="createBtn" @click="createBtn">Add new Employee</button>
      </div>
      <div id="filter-options">
         <select name="filter" id="filter-element" v-model="filterOption" @change="handleFilterChange">
            <option value="all">All</option>
            <option value="postion">Position</option>
            <option value="asc">A-Z</option>
            <option value="desc">Z-A</option>
         </select>
      </div>
      </div>
    </div>
    <div class="empList-content-div">
      <AddEmployee v-if="show"
      @submit="submit"
      @handleMessage="handleMessage"
      :employeeCount="totalItems"
      @error="erroOccur"
      @exitModal="closeModal" />
      <SuccessModal
      v-if="showSuccess"
      @exitSuccess="closeSuccess"
      :message="msg"
      />
      <ErrorModal
      v-if="showError"
      @exitModal="closeError"
      :message="msg"
      />
      <ReAuth
        v-if="showAuth"
        @handleApprove="approval"
        @close="closeReauth"/>
      <div class="employee-list-container">
        <!--loading-->
        <div id="loading" v-if="loading">
          <font-awesome-icon icon="spinner"/>
          <p>Loading</p>
        </div>
        <!--without data-->
        <div id="employee-list" v-else-if="noData">
          <font-awesome-icon icon="inbox"/>
          <p>No Data</p>
        </div>
        <!--with data-->
        <div id="employee-data-div"
        v-else v-for="list in computedEmployee"
        :key="list.id">
          <a class="img-name-container" :href="'/admin/user/' + list.id">
            <img class="employee-img" :src="list.photoUrl">
            <p>{{ list.firstName + " " + list.lastName}}</p>
          </a>
          <div class="date-dlt-container">
            <p>Added on {{ list.date }}</p>
            <div id="emp-dlt" @click="deleteUser(list.id)">
              <font-awesome-icon icon="trash"/>
              <p>DELETE</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
         <PaginationComponent
          :totalPages="totalPages"
          :perPage="itemsPerPage"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          />
      </div>
    </div>
    <div class="empList-footer-div"></div>
 </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue'
import AddEmployee from '@/modal/AddEmployee.vue'
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'
import ReAuth from '@/modal/ReAuth.vue'
import axios from '@/api/axios'
import ViewEmployee from './ViewEmployee.vue'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'AdminAttendance',
  components: {
    PaginationComponent,
    AddEmployee,
    SuccessModal,
    ErrorModal,
    ViewEmployee,
    ReAuth
  },
  data () {
    return {
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 5,
      employee: [],
      employeePhoto: '',
      loading: true,
      noData: false,
      withData: false,
      show: false,
      showSuccess: false,
      showError: false,
      showAuth: false,
      searchQuery: '',
      filterOption: 'all',
      msg: ''
    }
  },
  computed: {
    computedEmployee () {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Return a slice of your data for the current page
      return this.employee.slice(startIndex, endIndex)
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    },
    ...mapState(['authData'])
  },
  methods: {
    searchBtn (e) {
      e.preventDefault()
      this.withData = false
      this.loading = true
      this.employee = []
      setTimeout(() => {
        this.employeeData()
        this.loading = false
      }, 2000)
    },
    handleFilterChange (e) {
      e.preventDefault()
      this.withData = false
      this.loading = true
      this.employee = []
      setTimeout(() => {
        this.employeeData()
        this.loading = false
      }, 500)
    },
    approval (val) {
      this.approval = val
    },
    createBtn (e) {
      e.preventDefault()
      this.show = !this.show
    },
    onPageChange (page) {
      this.currentPage = page
    },
    async employeeData () {
      try {
        const cookieValue = sessionStorage.getItem('token')
        const search = this.searchQuery // Get the search query
        const filter = this.filterOption // Get the selected filter option

        // Construct the API URL with search and filter parameters
        const apiUrl = `/auth/creds?search=${search}&filter=${filter}`

        const config = {
          headers: {
            Authorization: `${cookieValue}`
          }
        }

        const data = await axios.get(apiUrl, config)

        if (data.status === 200) {
          const filteredData = data.data.filter((i) => i.id !== this.authData.Uid)
          const storage = getStorage()

          // Create an array of promises to fetch download URLs concurrently
          const urlPromises = filteredData.map(async (id) => {
            const modifiedUrl = id.photoUrl.replace(
              'https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images',
              '/images'
            )
            const forestRef = ref(storage, modifiedUrl)

            try {
              const url = await getDownloadURL(forestRef)
              // Modify the photoUrl in the original data
              id.photoUrl = url
            } catch (error) {
              console.error('Error fetching download URL:', error)
              // Handle errors appropriately, for example, set photoUrl to null
              id.photoUrl = null
            }
          })

          // Wait for all promises to resolve
          await Promise.all(urlPromises)

          // Assign the filtered and modified data to this.employee
          this.employee = filteredData

          this.totalItems = this.employee.length
          this.searchQuery = ''
          // update the route of the system
          this.$router.push(`/admin/employee-list?search=${search}&filter=${filter}`)
          this.loading = false
        }

        if (this.employee.length === 0) {
          this.noData = true
          this.withData = false
        } else {
          this.noData = false
          this.withData = true
        }
      } catch (error) {
        console.error('Error fetching employee data:', error)
      }
    },
    async deleteUser (id) {
      const cookieValue = sessionStorage.getItem('token')

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      this.showAuth = true
      if (this.approval === 'yes') {
        const deleteRes = await axios.put('/employee/user/delete', { Id: id }, config)
        if (deleteRes.status === 200) {
          this.msg = 'Employee deleted!'
          this.showSuccess = true
          this.employeeData()
        }
      } else if (this.approval === 'no') {
        this.msg = 'Admin password wrong'
        this.showError = true
      }
    },
    handleMessage (val) {
      this.msg = val
    },
    submit () {
      this.closeModal()
      this.employeeData()
      this.showSuccess = true
    },
    erroOccur () {
      this.closeModal()
      this.showError = true
    },
    closeModal () {
      this.show = !this.show
    },
    closeSuccess () {
      this.showSuccess = false
    },
    closeError () {
      this.showError = false
    },
    closeReauth () {
      this.showAuth = false
    }
  },
  created () {
    // Fetch initial employee data when the component is created
    setTimeout(() => {
      this.employeeData()
    }, 7000)
  }
})
</script>
