<template>
    <div class="schedule-div">
        <div class="sched-nav-div">
          <p class="sched-title">Employee Schedule</p>
        </div>
        <div class="sched-content-div">
          <ScheduleModal
            v-if="updateModal"
            :events="targetEvent"
            @success="success"
            @handleMessage="handleMessage"
            @error="error"
            @exitSched="exitSched"
            />
            <CreateSchedule
            v-if="createModal"
            @success="success"
            @handleMessage="handleMessage"
            @error="error"
            @exitSched="exitCreateSched"
            />
            <SuccessModal
            v-if="successModal"
            :message="msg"
            @exitSuccess="exitSuccess"
            />
            <ErrorModal
            v-if="errorModal"
            :message="msg"
            @exitError="exitError"
            />
          <div class="create-container">
            <button class="create-sched-btn" @click="createSched">Create Schedule</button>
          </div>
          <FullCalendar class="calendar-content" :options="calendarOptions"/>
        </div>
        <div class="sched-footer-div"></div>
    </div>
</template>

<script>
import ScheduleModal from '@/modal/ScheduleModal.vue'
import CreateSchedule from '@/modal/CreateSchedule.vue'
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'

import FullCalendar from '@fullcalendar/vue3'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import Interaction from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
import axios from '@/api/axios'

export default (await import('vue')).defineComponent({
  name: 'ScheduleModule',
  data () {
    return {
      calendarOptions: {
        plugins: [DayGridPlugin, TimeGridPlugin, Interaction, ListPlugin],
        initialView: 'dayGridMonth', // Sets the initial view
        headerToolbar: {
          left: 'prev today next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: [],
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        eventContent: this.renderEvent
      },
      updateModal: false,
      createModal: false,
      successModal: false,
      errorModal: false,
      msg: '',
      targetEvent: []
    }
  },
  components: {
    FullCalendar,
    ScheduleModal,
    CreateSchedule,
    SuccessModal,
    ErrorModal
  },
  methods: {
    handleMessage (val) {
      this.msg = val
    },
    renderEvent (arg) {
      const event = arg.event
      const descdiv = document.createElement('div')
      const italicEl = document.createElement('p')
      const icon = document.createElement('span')
      const desc = document.createElement('p')

      italicEl.textContent = `${event._def.title}`
      icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>'
      desc.textContent = `${event._def.extendedProps.description}`
      descdiv.classList.add('event-desc-container')
      descdiv.appendChild(icon)
      descdiv.appendChild(desc)
      const arrayOfDomNodes = [italicEl, descdiv]
      return { domNodes: arrayOfDomNodes }
    },
    handleDateClick (arg) {
      const today = new Date()
      if (arg.date < today) {
        alert('Date selected have passed')
      } else {
        this.createModal = !this.createModal
      }
    },
    handleEventClick (arg) {
      console.log(arg)
      this.updateModal = !this.updateModal

      this.targetEvent = arg.event
    },
    createSched () {
      this.createModal = !this.createModal
    },
    exitSched () {
      this.updateModal = !this.updateModal
    },
    exitCreateSched () {
      this.createModal = !this.createModal
    },
    success () {
      this.successModal = !this.successModal
      this.updateModal = false
      this.createModal = false
      this.mySched()
    },
    error () {
      this.errorModal = !this.errorModal
      this.updateModal = false
      this.createModal = false
    },
    exitSuccess () {
      this.successModal = !this.successModal
    },
    exitError () {
      this.errorModal = !this.errorModal
    },
    async mySched () {
      const key = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${key}`
        }
      }
      const dayAbbreviationToNumber = {
        SUN: 0,
        M: 1,
        T: 2,
        W: 3,
        TH: 4,
        F: 5,
        SAT: 6
      }
      try {
        const getSched = await axios.get('/employee/allSched', config)
        if (getSched.status === 200) {
          let eventData = []

          eventData = getSched.data.map((sched) => {
            if (sched.Repeating === 'true') {
              return {
                id: sched.SchedId,
                groupId: sched.SchedId,
                title: sched.Title,
                description: sched.Description,
                extendedProps: {
                  selectedEmployees: sched.SelectedEmployees,
                  isRepeating: sched.Repeating,
                  Days: sched.Days.map(dayAbbrev => dayAbbreviationToNumber[dayAbbrev]),
                  startRecur: sched.StartDate + 'T' + sched.StartTime,
                  endRecur: sched.EndDate + 'T' + sched.EndTime
                },
                startTime: sched.StartTime,
                endTime: sched.EndTime,
                startRecur: sched.StartDate + 'T' + sched.StartTime,
                endRecur: sched.EndDate + 'T' + sched.EndTime,
                daysOfWeek: sched.Days.map(dayAbbrev => dayAbbreviationToNumber[dayAbbrev]),
                draggable: false,
                recurringEventId: sched.SchedId,
                eventColor: sched.Title.includes('AM') ? '#abd434' : sched.Title.includes('Break') ? 'gold' : '#ffffff'
              }
            } else {
              return {
                id: sched.SchedId,
                title: sched.Title,
                description: sched.Description,
                extendedProps: {
                  selectedEmployees: sched.SelectedEmployees,
                  start: sched.StartDate + 'T' + sched.StartTime,
                  end: sched.EndDate + 'T' + sched.EndTime
                },
                start: sched.StartDate + 'T' + sched.StartTime,
                end: sched.EndDate + 'T' + sched.EndTime,
                draggable: false,
                eventColor: sched.Title.includes('AM') ? '#abd434' : sched.Title.includes('Break') ? 'gold' : '#ffffff'
              }
            }
          })
          this.calendarOptions.events = eventData
        }
      } catch (e) {
        this.msg = 'error occur'
        this.error()
      }
    }
  },
  created () {
    this.mySched()
  }
})
</script>
