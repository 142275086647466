<template>
    <div class="login-div">
        <div class="wagyu-img">
          <img src="@/assets/received_202374629205091.webp" alt="wagyuniku" />
        </div>
        <div class="main-container">
          <p>LOGIN AS</p>
          <div class="selection-div">
            <button @click="adminButton" class="login-type-btn adminBtn">ADMIN</button>
            <button @click="employeeButton" class="login-type-btn employeeBtn active">EMPLOYEE</button>
          </div>
          <div class="main-form-container">
            <EmployeeLogin v-if="isEmployee"/>
            <AdminLogin v-if="isAdmin"/>
          </div>
        </div>
    </div>
</template>

<script>
import AdminLogin from '@/components/admin-components/AdminLogin.vue'
import EmployeeLogin from '@/components/employee-components/EmployeeLogin.vue'

export default {
  name: 'loginView',
  props: [],
  components: {
    AdminLogin,
    EmployeeLogin
  },
  data () {
    return {
      isAdmin: false,
      isEmployee: true
    }
  },
  methods: {
    adminButton () {
      if (!this.isAdmin) {
        this.isAdmin = !this.isAdmin
        const adminBtn = document.querySelector('.adminBtn')
        adminBtn.classList.add('active')
      }
      if (this.isEmployee) {
        const employeeBtn = document.querySelector('.employeeBtn')
        this.isEmployee = !this.isEmployee
        employeeBtn.classList.remove('active')
      }
    },
    employeeButton () {
      if (!this.isEmployee) {
        this.isEmployee = !this.isEmployee
        const employeeBtn = document.querySelector('.employeeBtn')
        employeeBtn.classList.add('active')
      }
      if (this.isAdmin) {
        const adminBtn = document.querySelector('.adminBtn')
        this.isAdmin = !this.isAdmin
        adminBtn.classList.remove('active')
      }
    }
  }

}
</script>
