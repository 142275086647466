<template>
 <AdminAttendance v-if="isAdmin" />
 <EmployeeAttendance v-if="isEmployee" />
</template>

<script>
import AdminAttendance from '@/components/admin-components/AdminAttendance.vue'
import EmployeeAttendance from '@/components/employee-components/EmployeeAttendance.vue'
export default (await import('vue')).defineComponent({
  name: 'AttendanceModule',
  data () {
    return {
      role: sessionStorage.getItem('role')
    }
  },
  components: {
    AdminAttendance,
    EmployeeAttendance
  },
  computed: {
    isAdmin () {
      return this.role === 'admin' // Check if the user is an admin
    },
    isEmployee () {
      return this.role === 'employee' // Check if the user is an employee
    }
  }
})
</script>
