export const sessionStoragePlugin = (store) => {
  // Load user data from sessionStorage when the store is initialized
  const userData = sessionStorage.getItem('user-data')
  if (userData) {
    store.commit('setUserData', JSON.parse(userData))
  }

  const authData = sessionStorage.getItem('auth-data')
  if (authData) {
    store.commit('setAuthData', JSON.parse(authData))
  }

  const changeDetails = sessionStorage.getItem('change-data')
  if (changeDetails) {
    store.commit('setchangeDetail', JSON.parse(changeDetails))
  }

  // Watch for changes to the user data and save them to sessionStorage
  store.subscribe((mutation, state) => {
    if (mutation.type === 'setUserData') {
      sessionStorage.setItem('user-data', JSON.stringify(state.userData))
    }
  })

  // Watch for changes to the user data and save them to sessionStorage
  store.subscribe((mutation, state) => {
    if (mutation.type === 'setAuthData') {
      sessionStorage.setItem('auth-data', JSON.stringify(state.authData))
    }
  })

  // saves changes of details from session storage
  store.subscribe((mutation, state) => {
    if (mutation.type === 'setchangeDetail') {
      sessionStorage.setItem('change-data', JSON.stringify(state.changeDetails))
    }
  })
}
