<template>
    <div class="success-modal">
        <div id="modal-body">
            <header>
                <p id="change-detail-title">Success</p>
                <font-awesome-icon class="exit-msg" icon="xmark" @click="exitModal"/>
            </header>
            <main>
                <h1>{{ message }}</h1>
            </main>
        </div>
    </div>
</template>

<script>
export default (await import('vue')).defineComponent({
  name: 'success-modal',
  props: {
    message: String
  },
  methods: {
    exitModal () {
      this.$emit('exitSuccess')
    }
  }
})
</script>
