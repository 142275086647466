<template>
    <div class="employee-view-loading" v-if="isloading">
      <font-awesome-icon icon="spinner"/>
      <p>Loading</p>
    </div>
    <div class="employee-view-div" v-else v-for="data in user" :key="data.id">
      <SuccessModal
        v-if="showSuccess"
        :message="msg"
        @exitSuccess="closeModal"/>
      <ErrorModal
        v-if="showError"
        :message="msg"
        @exitModal="closeModal"/>
        <div class="view-header">
            <div class="back-btn-div" @click="backClicked">
              <font-awesome-icon icon="arrow-left"/>
              <p>Back</p>
            </div>
            <div class="userProfile">
                <div class="first-container">
                    <img :src="data.photoUrl" alt="user">
                    <p>{{ data.firstName + ' ' + data.lastName }}</p>
                </div>
                <div class="second-container">
                    <p>Added on {{ data.date }}</p>
                    <div id="emp-dlt" @click="restore" v-if="data.archive === true">
                        <font-awesome-icon icon="eye-slash"/>
                        <p>RESTORE</p>
                    </div>
                    <div id="emp-dlt" @click="archive" v-else>
                        <font-awesome-icon icon="eye-slash"/>
                        <p>ARCHIVE</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="view-main">
            <div class="profile-name-email">
                <h1 class="details-title">PROFILE IMAGE</h1>
                <img :src="avatar" alt="user image">
                <form class="profile-btn-form">
                <label for="uploadBtn" id="uploadBtnLbl">
                  <font-awesome-icon icon="camera"/>
                  Change profile image
                </label>
                <input
                  name="uploadBtn"
                  type="file"
                  id="uploadBtn"
                  @change="handleImg"
                  hidden
                />
              </form>
              <div id="fnNm-div">
                <label for="firstName">First Name</label>
                <input
                    name="firstName"
                    id="firstName"
                    v-model="data.firstName"
                    type="text"/>
              </div>
              <div id="mdlNm-div">
                <label for="middleName">Middle Name</label>
                <input
                    name="middleName"
                    id="middleName"
                    v-model="data.middleName"
                    type="text"/>
              </div>
              <div id="lstNm-div">
                <label for="lastName">Last Name</label>
                <input
                    name="lastName"
                    id="lastName"
                    v-model="data.lastName"
                    type="text"/>
              </div>
              <div id="email-copy-div">
                <label for="email">Email</label>
                <div id="email-copy">
                  <input
                  name="email"
                  id="email"
                  v-model="data.personalEmail"
                  type="text"
                    />
                    <font-awesome-icon class="copy-email" icon="copy" @click="copyBtn"/>
                </div>
              </div>
            </div>
            <div class="num-role-status">
              <div id="phNumber-copy-div">
                  <label for="phNumber">Phone Number</label>
                  <div id="phNumber-copy">
                    <input
                      name="phNumber"
                      id="phNumber"
                      v-model="data.phoneNumber"
                      type="text"
                        />
                    <font-awesome-icon class="copy-num" icon="copy" @click="copyBtn"/>
                  </div>
              </div>
              <h1 class="details-title">ROLE</h1>
              <div id="position-div">
              <label for="position">Station:</label>
                  <select name="position" id="position" v-model="station">
                    <option value="FOH">Front of the house</option>
                    <option value="BOH">Back of the house</option>
                  </select>
              </div>
              <div id="foh-position-div" v-if="isFoh">
                <label for="foh-position">Front of the house: </label>
                    <select name="foh-position" id="foh-position" v-model="data.role">
                      <option value="barista">Barista</option>
                      <option value="cashier">Cashier</option>
                      <option value="admin">Restaurant Manager(admin)</option>
                      <option value="server">Server</option>
                      <option value="security guard">Security Guard</option>
                    </select>
              </div>
              <div id="boh-position-div" v-if="isBoh">
                <label for="boh-position">Back of the house: </label>
                    <select name="boh-position" id="boh-position" v-model="data.role">
                      <option value="head chef">Head Chef</option>
                      <option value="line cook">Line Cook</option>
                      <option value="headAdmin">head Manager</option>
                    </select>
              </div>
              <h1 class="details-title">CURRENT STATUS</h1>
              <div id="status-div">
                <label for="status">Status:</label>
                    <select name="status" id="status" v-model="data.status">
                      <option value="Onboarding">Onboarding</option>
                      <option value="Outboarding">Outboarding</option>
                      <option value="Probationary">Probation</option>
                      <option value="Regular">Regular</option>
                      <option value="Suspended">Suspended</option>
                    </select>
              </div>
              <div id="wage-div">
              <label for="wage">Wage:</label>
                      <input
                        type="number"
                        placeholder="Wage... (Ex. 75.50)"
                        id="wage"
                        v-model="data.wage"
                        name="wage"
                        step=0.01
                      />
            </div>
            </div>
        </div>
        <div class="view-footer">
          <button @click="UpdateEmployee">Update Employee</button>
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import SuccessModal from '@/modal/SuccessModal.vue'
import ErrorModal from '@/modal/ErrorModal.vue'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'view-user',
  data () {
    return {
      user: [],
      photoUrl: null,
      station: null,
      isFoh: false,
      isBoh: false,
      showSuccess: false,
      showError: false,
      msg: '',
      changes: '',
      isloading: true,
      photoChange: false
    }
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    SuccessModal,
    ErrorModal
  },
  watch: {
    station: {
      deep: true,
      handler (newVal) {
        switch (newVal) {
          case 'FOH':
            this.isBoh = false
            this.isFoh = true
            break
          case 'BOH':
            this.isFoh = false
            this.isBoh = true
            break
        }
      }
    }
  },
  computed: {
    computedUserData () {
      // Use a computed property to determine which data to display
      return this.changes ? this.changeDetails : this.userData
    },
    ...mapState(['userData']),
    ...mapState(['authData']),
    avatar () {
      // Map the avatar property from your store's userData to a local computed property
      this.imageURL = this.user[0].photoUrl
      return this.photoChange ? this.user[0].photoUrl : this.imageURL
    }
  },
  methods: {
    async fetchUser () {
      const cookieValue = sessionStorage.getItem('token')
      const id = this.$route.params.userId

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const user = await axios.get('/auth/creds', config)
        if (user.status === 200) {
          const filteredData = user.data.filter(item => item.id === id)
          const urlPromise = filteredData.map(async (id) => {
            const modifiedUrl = id.photoUrl.replace('https://storage.googleapis.com/hrisps---wagyuniku.appspot.com/images', '/images')
            const storage = getStorage()
            const forestRef = ref(storage, modifiedUrl)
            try {
              const url = await getDownloadURL(forestRef)
              // nested loop to modify the url
              id.photoUrl = url
            } catch (error) {
              this.photoUrl = null // Set avatarURL to null in case of error
            }
          })

          await Promise.all(urlPromise)
          this.user = filteredData
        }
        if (this.user[0].role.includes('barista') ||
            this.user[0].role.includes('cashier') ||
            this.user[0].role.includes('restaurant manager') ||
            this.user[0].role.includes('server') ||
            this.user[0].role.includes('security guard')) {
          this.station = 'FOH'
        } else {
          this.station = 'BOH'
        }
        this.isloading = false
      } catch (e) {
        console.log(e)
        alert('error occur')
      }
    },
    async UpdateEmployee () {
      const token = sessionStorage.getItem('token')
      const id = this.user[0].id
      const formData = new FormData()
      if (this.user[0].photoUrl && !this.user[0].photoUrl.includes('https')) {
        formData.append('id', id)
        formData.append('photoUrl', this.user[0].photoUrl)
        formData.append('lastName', this.user[0].lastName)
      }
      if (this.user[0].phoneNumber) {
        formData.append('id', id)
        formData.append('phoneNumber', this.user[0].phoneNumber)
      }
      if (this.user[0].personalEmail) {
        formData.append('id', id)
        formData.append('personalEmail', this.user[0].personalEmail)
      }
      if (this.user[0].role) {
        formData.append('id', id)
        formData.append('role', this.user[0].role)
      }
      if (this.user[0].status) {
        formData.append('id', id)
        formData.append('role', this.user[0].status)
      }
      if (this.user[0].wage) {
        formData.append('id', id)
        formData.append('role', this.user[0].wage)
      }
      try {
        const updateProfile = await axios.put('/update/admin',
          formData, {
            headers: {
              Authorization: `${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (updateProfile.status === 200) {
          this.msg = 'Successfully change user details'
          this.showSuccess = true
          setTimeout(() => {
            this.fetchUser()
          }, 2000)
        }
      } catch (e) {
        console.log(e)
        this.msg = 'Failed to change employee details'
        this.showError = true
      }
    },
    async handleImg (e) {
      const file = e.target.files[0]
      // Use FileReader to read the image and set userDetails.avatar
      const reader = new FileReader()
      reader.onload = (event) => {
        this.user[0].photoUrl = event.target.result

        this.photoChange = !this.photoChange
      }

      reader.readAsDataURL(file)
    },
    copyBtn (e) {
      const target = e.target.classList
      if (target.contains('copy-email')) {
        // Get the input element by its ID
        const inputElement = document.querySelector('#email')
        // Select the text inside the input element
        inputElement.select()

        // Copy the selected text to the clipboard
        document.execCommand('copy')
      } else if (target.contains('copy-num')) {
        // Get the input element by its ID
        const inputElement = document.querySelector('#phNumber')
        // Select the text inside the input element
        inputElement.select()

        // Copy the selected text to the clipboard
        document.execCommand('copy')
      }
    },
    backClicked () {
      this.$router.back(-1)
    },
    async restore () {
      const token = sessionStorage.getItem('token')
      const Id = { id: this.$route.params.userId }

      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      try {
        const archiveRes = await axios.put('/employee/user/Restore', Id, config)
        if (archiveRes.status === 200) {
          this.msg = `Successfully archive ${this.user[0].firstName}`
          this.showSuccess = true
        }
      } catch (e) {
        this.msg = 'Failed to archive user'
        this.showError = true
      }
    },

    async archive () {
      const token = sessionStorage.getItem('token')
      const Id = { id: this.$route.params.userId }

      const config = {
        headers: {
          Authorization: `${token}`
        }
      }
      try {
        const archiveRes = await axios.put('/employee/user/archive', Id, config)
        if (archiveRes.status === 200) {
          this.msg = `Successfully archive ${this.user[0].firstName}`
          this.showSuccess = true
        }
      } catch (e) {
        this.msg = 'Failed to archive user'
        this.showError = true
      }
    },
    closeModal () {
      this.showError = false
      this.showSuccess = false
    }
  },
  mounted () {
    this.fetchUser()
  }
})
</script>
