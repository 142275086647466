import attendance from '@/views/AttendanceModule.vue'
import mainComponent from '@/views/employee-view/EmployeeMainDash.vue'
import userSettings from '@/components/UserPage.vue'
import EmployeeSched from '@/views/employee-view/EmployeeSched.vue'
import EmployeePayroll from '@/views/employee-view/EmployeePayroll.vue'

const authRoleGuard = (to, from, next) => {
  const isAuthenticated = sessionStorage.getItem('token') !== null
  const role = sessionStorage.getItem('role')
  const mainTitle = []

  // Updates the navTitle base on url
  document.addEventListener('DOMContentLoaded', () => {
    const navTitle = document.querySelector('.system-title')
    mainTitle.push(navTitle.textContent)

    if (to.path === '/employee/attendance-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - ATTENDANCE RECORD`
      console.log(mainTitle)
    } else if (to.path === '/employee/schedule-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - SCHEDULE`
    } else if (to.path === '/employee/payroll-module') {
      navTitle.textContent = ''
      navTitle.textContent = `${mainTitle.toString()} - PAYROLL`
    }
  })

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/')

    // Redirect to the appropriate route if user wants to go back to login
  } else if (from.path === '/employee/attendance-module' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/employee/schedule-module' && to.path === '/') {
    next('/' + role)
  } else if (from.path === '/employee/payroll-module' && to.path === '/') {
    next('/' + role)
  } else if (to.meta.role && to.meta.role !== role) {
    next('/' + role)
  } else {
    next()
  }
}

export default [
  {
    path: '',
    children: [
      {
        path: '',
        name: 'mainCompoment',
        component: mainComponent,
        meta: {
          role: 'employee',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'user-settings',
        component: userSettings,
        meta: {
          role: 'employee',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'attendance-module',
        component: attendance,
        meta: {
          role: 'employee',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'schedule-module',
        component: EmployeeSched,
        meta: {
          role: 'employee',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      },
      {
        path: 'payroll-module',
        component: EmployeePayroll,
        meta: {
          role: 'employee',
          requiresAuth: true
        },
        beforeEnter: authRoleGuard
      }
    ]
  }
]
