<template>
    <div class="navBar-div">
        <a class="sidebar-btn-div" @click="sidebarBtn"><font-awesome-icon icon="bars"/></a>
        <h1 class="system-title">WAGYUNIKU BFRV</h1>
        <div class="profile-div">
          <div class="profile-img-div">
            <img class="nav-profile-img" :src="avatarURL" alt="User Avatar" />
          </div>
          <h4 class="name-txt">{{ userData.firstName }}</h4>
          <font-awesome-icon icon="caret-down" @click="showSettings"/>
          <ProfileSettings v-if="show"/>
        </div>
    </div>
</template>

<script>
import ProfileSettings from '@/components/ProfileSettings.vue'
import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'Navbar',
  components: {
    ProfileSettings
  },
  data () {
    return {
      sidebar: false,
      show: false,
      avatarURL: null,
      isChangesMade: false
    }
  },
  watch: {
    authData: {
      handler: 'avatar', // Calls the 'avatar' method when authData changes
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  created () {
    // Call the avatar function when the component is created
    this.avatar()
  },
  methods: {
    async avatar () {
      this.avatarURL = this.authData.photoUrl
    },
    sidebarBtn () {
      const sidebarDiv = document.querySelector('.sidebar-div')
      this.sidebar = !this.sidebar
      if (this.sidebar) {
        sidebarDiv.classList.add('active')
      }
    },
    showSettings () {
      this.show = !this.show
    }
  }
})

</script>
