<template>
    <div class="employee-dashboard">
        <TimeIn
        v-if="showTimeIn"
        :message="msg"
        :class="classStr"
        @close="closeTimeIn"/>
        <ReAuth
        v-if="showReAuth"
        @handleApprove="approve"
        @close="closeReAuth"/>
        <div class="attendance-container">
            <div class="title-txt">
                <h1>TOTAL ATTENDANCE</h1>
            </div>
            <div class="date-txt">
                <p>DATE</p>
                <p>{{ dateToday.customFormat }}</p>
            </div>
            <div class="profile-div">
                <img :src="authData.photoUrl" alt="user-profile">
            </div>
            <div class="user-div">
                <p>Welcome!</p>
                <p>{{ userData.firstName }}</p>
            </div>
            <div class="present-count">
                <p> {{ present + ' / ' + numberOfDays}}</p>
                <span>{{ dateToday.formattedDate }}</span>
            </div>
            <div class="button-div">
                <button class="time-in-btn" @click="attendance" :disabled="!attend">TIME - IN</button>
                <button class="break-btn" @click="breakT" :disabled="!breakTime">BREAK TIME</button>
                <button class="time-out-btn" @click="timeOut" :disabled="!out">TIME - OUT</button>
                <button class="overtime-btn" @click="overTime" :disabled="!overtime">OVERTIME</button>
            </div>
        </div>
        <div class="absent-container">
            <div class="title-txt">
                <h1>TOTAL OF ABSENCES: <span id="count">{{ absent.length }}</span></h1>
            </div>
            <div class="absent-list-div">
              <!--Loading-->
              <div class="absent-list-loading" v-if="loading1">
                <font-awesome-icon icon="spinner"/>
                <p>Loading</p></div>
              <!--noData-->
              <div class="absent-list-noData" v-else-if="noAbsence && absent.length === 0">
                <font-awesome-icon icon="inbox"/>
                <p>No Data</p>
              </div>
              <!--withData-->
              <div class="absent-list-data" v-else v-for="data in computedAttendance" :key="data.id">
                <h2 id="absence-date">{{ data.date }}</h2>
                <h2 id="type-name">{{ data.type }}</h2>
              </div>
            </div>
            <PaginationComponent
              :totalPages="totalAttendancePages"
              :perPage="5"
              :currentPage="currentAttendance"
              @onPageChange="onAttendanceChange"
            />
        </div>
          <div class="payroll-count-div">
            <div id="pay-txt"><p>PAYROLL</p></div>
            <div class="pay-list" v-if="loading2">
              <div id="name-div">
                <p class="name-title">DATE</p>
                <!--loading-->
                <div id="loading">
                  <font-awesome-icon icon="spinner"/>
                  <p>Loading</p>
                </div>
              </div>
              <div id="salary-div">
                <p class="salary-title">SALARY</p>
                <!--loading-->
                <div id="loading">
                  <font-awesome-icon icon="spinner"/>
                  <p>Loading</p>
                </div>
              </div>
            </div>
            <div class="pay-list" v-else-if="noSalary">
              <div id="name-div">
                <p class="name-title">DATE</p>
                <!--noData-->
                <div class="list-noData">
                  <font-awesome-icon icon="inbox"/>
                  <p>No Data</p>
                </div>
              </div>
              <div id="salary-div">
                <p class="salary-title">SALARY</p>
                <!--noData-->
                <div class="list-noData">
                  <font-awesome-icon icon="inbox"/>
                  <p>No Data</p>
                </div>
              </div>
            </div>
            <div class="pay-list" v-else>
                <div id="name-div">
                    <p class="name-title">DATE</p>
                    <!--withData-->
                    <ul class="employeeName" v-for="data in salary" :key="data.id">
                    <li @click="handleRedirect(data.id)">
                      {{ data.startDate + '-' + data.endDate }}
                    </li>
                    </ul>
                </div>
                <div id="salary-div">
                    <p class="salary-title">SALARY</p>
                    <!--withData-->
                    <ul class="employeeName" v-for="data in salary" :key="data.id">
                      <li @click="handleRedirect(data.id)">
                        {{ data.grossEarnings + ' Php' }}
                      </li>
                    </ul>
                </div>
            </div>
            <PaginationComponent
              :totalPages="totalPayrollPage"
              :perPage="5"
              :currentPage="currentPayroll"
              @onPageChange="onPayrollChange"
            />
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import axios2 from '@/api/axios'
import TimeIn from '@/modal/TimeIn.vue'
import ErrorModal from '@/modal/ErrorModal.vue'
import ReAuth from '@/modal/ReAuth.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'

import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'mainComponent',
  components: {
    TimeIn,
    ErrorModal,
    ReAuth,
    PaginationComponent
  },
  data () {
    return {
      showTimeIn: false,
      showReAuth: false,
      msg: '',
      classStr: '',
      attend: false,
      breakTime: false,
      out: false,
      overtime: false,
      start: null,
      end: null,
      breakStart: null,
      breakEnd: null,
      today: null,
      salary: [],
      absent: [],
      present: [],
      numberOfDays: null,
      isBreak: false,
      isOvetTime: false,
      loading1: true,
      loading2: true,
      noSalary: false,
      noAbsence: false,
      timeInTime: null,
      breakStartTime: null,
      breakEndTime: null,
      outTime: null,
      overtimeTime: null,
      approve: null,
      isHoliday: '',
      currentAttendance: 1,
      currentPayroll: 1,
      totalAttendance: 0,
      totalPayroll: 0,
      itemsPerPage: 5
    }
  },
  computed: {
    computedAttendance () {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentAttendance - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Return a slice of your data for the current page
      return this.absent.slice(startIndex, endIndex)
    },
    computedPayroll () {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPayroll - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Return a slice of your data for the current page
      return this.salary.slice(startIndex, endIndex)
    },
    totalPayrollPage () {
      return Math.ceil(this.totalPayroll / this.itemsPerPage)
    },
    totalAttendancePages () {
      return Math.ceil(this.totalAttendance / this.itemsPerPage)
    },
    dateToday () {
      const options1 = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'Asia/Manila'
      }

      const options2 = {
        month: 'long',
        year: 'numeric',
        timeZone: 'Asia/Manila'
      }

      const date = new Date()
      const formattedDate1 = date.toLocaleDateString('en-PH', options1)
      const formattedDate = date.toLocaleDateString('en-PH', options2)

      const [month, day, year] = formattedDate1.split('/')
      const customFormat = `${day.trim()} - ${month.trim()} - ${year.trim()}`

      return { customFormat, formattedDate }
    },
    ...mapState(['userData']),
    ...mapState(['authData'])
  },
  methods: {
    handleRedirect (id) {
      this.$router.push('payroll-module')
    },
    onAttendanceChange (page) {
      this.currentAttendance = page
    },
    onPayrollChange (page) {
      this.currentPayroll = page
    },
    async getAttendance () {
      const today = new Date()
      const currentMonth = today.getMonth()
      const currentYear = today.getFullYear()

      // To get the last day of the current month
      const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)

      // Get the day of the month (number of days)
      this.numberOfDays = lastDayOfMonth.getDate()
      const apiUrl = `/Attendance/records?search=${this.userData.firstName}`
      const cookieValue = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      const data = await axios2.get(apiUrl, config)
      if (data.status === 200) {
        this.present = data.data.filter(e => e.type === 'present' || e.type === 'late').length
        this.totalItems = this.attendance.length
        this.absent = data.data.filter(i => i.type === 'absent')
        this.totalAttendance = this.absent.length
      }
      console.log(this.absent.length)
      if (this.absent.length === 0) {
        this.loading1 = false
        this.noAbsence = true
      } else {
        this.loading1 = false
        this.noAbsence = false
      }
    },
    async getPayroll () {
      const cookieValue = sessionStorage.getItem('token')
      const params = { id: this.authData.Uid }
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      try {
        const payrollRes = await axios2.get('/payroll/user/payroll-history', { params, ...config })
        if (payrollRes.status === 200) {
          this.salary = payrollRes.data
          this.totalPayroll = this.salary.length
        }
        if (this.salary.length === 0) {
          this.loading2 = false
          this.noSalary = true
        } else {
          this.loading2 = false
          this.noSalary = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getSched () {
      const cookieValue = sessionStorage.getItem('token')

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      const params = {
        id: this.authData.Uid
      }

      try {
        let startShift
        let endShift
        let startBreak
        let endBreak
        const sched = await axios2.get('/employee/user/sched', { params, ...config })
        if (sched.data.schedules) {
          const dateToday = new Date()
          const options1 = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Manila'
          }
          const numberToDayAbbreviation = {
            0: 'SUN',
            1: 'M',
            2: 'T',
            3: 'W',
            4: 'TH',
            5: 'F',
            6: 'SAT'
          }
          const timeFormatter = new Intl.DateTimeFormat('en-PH', options1)

          for (const i of sched.data.schedules) {
            if (i.title.includes('PM') || i.title.includes('AM')) {
              if (i.days !== null && i.days.includes(numberToDayAbbreviation[dateToday.getDay()])) {
                if (i.endDate) {
                // Repeating schedule
                  const currentDate = new Date()
                  const startDate = new Date(i.startDate + 'T' + i.startTime)
                  const endDate = new Date(i.endDate + 'T' + i.startTime)
                  if (currentDate >= startDate && currentDate <= endDate) {
                    startShift = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), i.startTime.split(':')[0], i.startTime.split(':')[1])
                    endShift = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), i.endTime.split(':')[0], i.endTime.split(':')[1])
                  } else {
                    startShift = null
                  }
                }
              } else {
                startShift = new Date(i.startDate + 'T' + i.startTime)
                endShift = new Date(i.endDate + 'T' + i.endTime)
              }
            }
          }
          for (const i of sched.data.schedules) {
            if (i.title.includes('Break') || i.title.includes('break')) {
              if (i.days !== null && i.days.includes(numberToDayAbbreviation[dateToday.getDay()])) {
                if (i.endDate) {
                  const currentDate = new Date()
                  const startDate = new Date(i.startDate)
                  const endDate = new Date(i.endDate)
                  if (currentDate >= startDate && currentDate <= endDate) {
                    startBreak = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), i.startTime.split(':')[0], i.startTime.split(':')[1])
                    endBreak = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), i.endTime.split(':')[0], i.endTime.split(':')[1])
                  } else {
                    startBreak = null
                    endBreak = null
                  }
                }
                break
              } else {
                startBreak = new Date(i.startDate + 'T' + i.startTime)
                endBreak = new Date(i.endDate + 'T' + i.endTime)
              }
            }
          }
          const formatted = timeFormatter.format(dateToday)
          const formattedStart = timeFormatter.format(startShift)
          const formattedEnd = timeFormatter.format(endShift)
          this.today = formatted
          this.start = formattedStart
          this.end = formattedEnd
          this.breakStart = startBreak
          this.breakEnd = endBreak
          const attendance = await axios2.get('/Attendance/records', config)
          let filterAttendance = []

          if (attendance.data === undefined) {
            filterAttendance = attendance
          }

          for (const i of attendance.data) {
            if (i.firstName === this.userData.firstName) {
              filterAttendance.push(i)
            }
          }
          const minutesDifference = this.getDifference(formattedStart, formatted)
          const endminutesDifference = this.getDifference(formattedEnd, formatted)
          // if (minutesDifference >= 120 && filterAttendance.length === 0) {
          //   this.saveAttendance('time-in', 'absent')
          // }
          if ((attendance.data.length === 0 || filterAttendance.length === 0) && minutesDifference <= 120 && endminutesDifference <= 60) {
            this.attend = true
          }
          for (const i of filterAttendance) {
            const iMonth = new Date(i.date).getMonth() + 1
            const iDay = new Date(i.date).getDate()
            const iYear = new Date(i.date).getFullYear()
            const month = new Date().getMonth() + 1
            const day = new Date().getDate()
            const year = new Date().getFullYear()
            if ((`${iMonth}-${iDay}-${iYear}` === `${month}-${day}-${year}`) ||
                (`${iMonth}-${iDay}-${iYear}` === `${month}-${day - 1}-${year}`)) {
              if (i.timeIn) {
                this.timeInTime = `${i.date}T${i.timeIn}`
              }
              if (i.breakStart) {
                this.breakStartTime = `${i.date}T${i.breakStart}`
              }
              if (i.breakEnd) {
                this.breakEndTime = `${i.date}T${i.breakEnd}`
              }
              if (i.timeOut) {
                this.outTime = `${i.date}T${i.timeOut}`
              }
              if (i.overTimeStart) {
                this.overtimeTime = `${i.date}T${i.overTimeStart}`
              }
              if ((this.timeInTime === null || this.outTime !== null) && (this.overtimeTime !== null || this.outTime !== null) && minutesDifference <= 120 && endminutesDifference <= 60) {
                this.attend = true
              }
              if (this.timeInTime !== null && startBreak <= new Date() && this.breakStart === null) {
                this.breakTime = true
                this.attend = false
              }
              if (this.timeInTime !== null && this.breakStart !== null && this.breakEnd === null) {
                this.breakTime = false
                this.attend = true
                this.isBreak = true
              }
              if (this.breakEndTime !== null && this.outTime === null && endminutesDifference !== 0 && endminutesDifference < 0) {
                this.attend = false
                this.breakTime = false
                this.out = true
              }
              if (this.overtimeTime === null && i.timeOut !== null && endminutesDifference !== 0 && endminutesDifference <= 60) {
                this.attend = false
                this.breakTime = false
                this.out = false
                this.overtime = true
              }
              if (this.overtimeTime !== null && endminutesDifference !== 0) {
                this.attend = false
                this.breakTime = false
                this.out = true
                this.overtime = false
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getDifference (start, end) {
      const startTime = new Date(start)
      const endTime = new Date(end)

      const timeDifference = endTime - startTime // Difference in milliseconds

      // Convert milliseconds to minutes
      const minutesDifference = Math.floor(timeDifference / (1000 * 60))

      return minutesDifference
    },
    getTotalTimeWithMinutes (start, end) {
      const startTime = new Date(start)
      const endTime = new Date(end)

      const timeDifference = endTime - startTime // Difference in milliseconds

      // Convert milliseconds to minutes
      const minutesDifference = Math.floor(timeDifference / (1000 * 60))

      const totalHours = Math.floor(minutesDifference / 60)
      const remainingMinutes = minutesDifference % 60

      return {
        totalHours,
        remainingMinutes,
        totalMinutes: minutesDifference
      }
    },
    async attendance () {
      const id = { id: this.userData.fingerId }
      // const stringifyId = JSON.stringify(id)
      try {
        this.msg = 'Processing...'
        this.classStr = 'classic'
        this.showTimeIn = true
        const attendnceRes = await axios2.post('/fingerprint/verify-fingerprint', id)
        if (this.isBreak === false) {
          if (attendnceRes.status === 200) {
            const minutesDifference = this.getDifference(this.start, this.today)
            console.log(minutesDifference)
            if (minutesDifference >= 0 && minutesDifference <= 15) {
              console.log('On time')
              this.saveAttendance('time-in', 'present', 0, minutesDifference)
            } else if (minutesDifference > 15 && minutesDifference <= 60) {
              console.log(`Late by ${minutesDifference} minutes`)
              this.saveAttendance('time-in', 'late', 0, minutesDifference)
            } else if (minutesDifference > 120) {
              console.log('Absent (late by more than 1 hour)')
              this.saveAttendance('time-in', 'absent')
            }

            this.showTimeIn = false

            this.msg = 'Time In Success'
            this.classStr = 'success'
            this.showTimeIn = true
          }
        } else if (this.isBreak === true) {
          if (attendnceRes.status === 200) {
            const minutesDifference = this.getDifference(this.breakStart, this.breakEnd)
            console.log(minutesDifference >= 0)
            if (minutesDifference > 0 && minutesDifference < 15) {
              console.log('On time')
              this.saveAttendance('break-end', 'break end', 0, 0)
            } else if (minutesDifference > 15 && minutesDifference < 60) {
              console.log(`Late by ${minutesDifference} minutes`)
              this.saveAttendance('break-end', 'late break end', 0, minutesDifference)
            }

            this.showTimeIn = false

            this.msg = 'End Break Success'
            this.classStr = 'success'
            this.showTimeIn = true
          }
        }
      } catch (e) {
        this.showTimeIn = false
        this.msg = 'Fingerprint not match'
        this.classStr = 'error'
        this.showTimeIn = true
      }
    },
    async breakT () {
      const cookieValue = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      const params = {
        id: this.authData.Uid
      }
      const id = { id: this.userData.fingerId }
      try {
        this.msg = 'Processing...'
        this.classStr = 'classic'
        this.showTimeIn = true
        const attendnceRes = await axios2.post('/fingerprint/verify-fingerprint', id)

        if (attendnceRes.status === 200) {
          this.closeTimeIn()
          const minutesDifference = this.getDifference(this.breakStart, this.today)
          const sched = await axios2.get('/employee/user/sched', { params, ...config })
          for (const i of sched.data.schedules) {
            console.log(i.title)
            if (i.title === 'Break Time' || i.title === 'break time') {
              console.log(minutesDifference)
              this.saveAttendance('break-start', 'break', 0, minutesDifference)
              this.getSched()

              this.msg = 'Break Success'
              this.classStr = 'success'
              this.showTimeIn = true
            }
          }
        }
      } catch (e) {
        console.log(e)
        this.closeTimeIn()
        this.msg = 'Fingerprint not match'
        this.classStr = 'error'
        this.showTimeIn = true
      }
    },
    async timeOut () {
      // const api = axios.create({
      //   baseURL: 'http://192.168.0.29:7075',
      //   timeout: 50000,
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })
      const id = { id: this.userData.fingerId }
      // const stringifyId = JSON.stringify(id)
      try {
        this.msg = 'Processing...'
        this.classStr = 'classic'
        this.showTimeIn = true
        const attendnceRes = await axios2.post('/fingerprint/verify-fingerprint', id)

        if (this.isOvetTime === false) {
          if (attendnceRes.status === 200) {
            const minutesDifference = this.getTotalTimeWithMinutes(this.start, this.today)
            if (minutesDifference) {
              this.saveAttendance('time-out', 'out', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            } else if (minutesDifference.totalMinutes > 15 && minutesDifference.totalMinutes <= 60) {
              this.saveAttendance('time-out', 'out', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            }

            this.showTimeIn = false

            this.msg = 'Time Out Success'
            this.classStr = 'success'
            this.showTimeIn = true
          }
        } else {
          if (attendnceRes.status === 200) {
            const minutesDifference = this.getTotalTimeWithMinutes(this.start, this.today)
            if (minutesDifference) {
              this.saveAttendance('overtime-end', 'overtime out', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            } else if (minutesDifference.totalMinutes > 15 && minutesDifference.totalMinutes <= 60) {
              this.saveAttendance('overtime-end', 'overtime out', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            }

            this.showTimeIn = false

            this.msg = 'Time In Success'
            this.classStr = 'success'
            this.showTimeIn = true
          }
        }
      } catch (e) {
        this.showTimeIn = false
        this.msg = 'Fingerprint not match'
        this.classStr = 'error'
        this.showTimeIn = true
      }
    },
    async overTime () {
      this.showReAuth = true
      if (this.approve === 'yes') {
        const id = { id: this.userData.fingerId }
        const stringifyId = JSON.stringify(id)
        try {
          this.msg = 'Processing...'
          this.classStr = 'classic'
          this.showTimeIn = true
          const attendnceRes = await axios2.post('/fingerprint/verify-fingerprint', stringifyId)

          if (attendnceRes.status === 200) {
            const minutesDifference = this.getTotalTimeWithMinutes(this.start, this.today)
            if (minutesDifference) {
              this.saveAttendance('overtime-start', 'Overtime', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            } else if (minutesDifference.totalMinutes > 15 && minutesDifference.totalMinutes <= 60) {
              this.saveAttendance('overtime-start', 'Overtime', minutesDifference.totalHours, minutesDifference.remainingMinutes)
            }

            this.showTimeIn = false

            this.msg = 'Time In Success'
            this.classStr = 'success'
            this.showTimeIn = true
          }
        } catch (e) {
          this.showTimeIn = false
          this.msg = 'Fingerprint not match'
          this.classStr = 'error'
          this.showTimeIn = true
        }
      }
    },
    async saveAttendance (btn, type, hours, remainingMinutes) {
      const id = this.authData.Uid
      const cookieValue = sessionStorage.getItem('token')
      const dateToday = new Date()
      const option2 = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Manila'
      }
      const option3 = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Manila'
      }
      const option4 = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Manila'
      }
      const date = dateToday.toLocaleDateString('en-US', option2)
      const time = dateToday.toLocaleTimeString('en-US', option3)
      const isHoliday = this.isHoliday
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }

      if (btn === 'time-in') {
        console.log('holiday:' + isHoliday, time)
        const request = { date, id, timeIn: time, firstName: this.userData.firstName, hour: hours, remainingMinutes: remainingMinutes, type, isHoliday }
        try {
          const timeIn = await axios2.post('Attendance/user/time', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      } else if (btn === 'break-start') {
        const request = { date, id, breakStart: time, firstName: this.userData.firstName, hour: hours, remainingMinutes: remainingMinutes, type, isHoliday }
        try {
          const timeIn = await axios2.post('Attendance/user/break-start', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      } else if (btn === 'break-end') {
        console.log(this.breakEnd)
        const request = { date, id, breakEnd: new Date(this.breakEnd).toLocaleTimeString('en-PH', option3), firstName: this.userData.firstName, hour: hours, remainingMinutes: remainingMinutes, type, isHoliday }
        console.log(request)
        try {
          const timeIn = await axios2.post('Attendance/user/break-end', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      } else if (btn === 'time-out') {
        const request = {
          date,
          id,
          timeOut: new Date().toLocaleTimeString('en-PH', option4),
          timeIn: this.timeInTime,
          breakStart: this.breakStartTime,
          breakEnd: this.breakEndTime,
          firstName: this.userData.firstName,
          hour: hours,
          remainingMinutes: remainingMinutes,
          type,
          isHoliday
        }
        try {
          const timeIn = await axios2.post('Attendance/user/out', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      } else if (btn === 'overtime-start') {
        const request = { date, id, overTimeStart: time, firstName: this.userData.firstName, hour: hours, remainingMinutes: remainingMinutes, type, isHoliday }
        try {
          const timeIn = await axios2.post('Attendance/user/break-end', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      } else if (btn === 'overtime-end') {
        const request = {
          date,
          id,
          overTimeEnd: new Date().toLocaleTimeString('en-PH', option4),
          overtime: this.overtimeTime,
          firstName: this.userData.firstName,
          hour: hours,
          remainingMinutes: remainingMinutes,
          type,
          isHoliday
        }
        try {
          const timeIn = await axios2.post('Attendance/user/break-end', request, config)
          if (timeIn.status === 200) {
            this.getSched()
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    closeTimeIn () {
      this.showTimeIn = false
      this.msg = ''
      this.classStr = ''
    },
    closeReAuth () {
      this.showReAuth = false
    },
    async checkHoliday () {
      const apiKey = '33104524-6857-43f9-8ff3-6461db6fd9c9'
      const countryCode = 'PH'
      const year = 2022
      const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
      const day = new Date().getDate().toString().padStart(2, '0')
      try {
        const url = `https://holidayapi.com/v1/holidays?key=${apiKey}&country=${countryCode}&year=${year}`
        const holiday = await axios2.get(url)
        if (holiday.status === 200) {
          const holidayDates = holiday.data.holidays.map(e => e.date)
          const currentDate = `${year}-${month}-${day}`
          if (holidayDates.includes(currentDate)) {
            this.isHoliday = 'true'
          } else {
            this.isHoliday = 'false'
          }
        }
        console.log(this.isHoliday)
      } catch (e) {
        console.log(e)
      }
    }
  },
  created () {
    this.getAttendance()
    this.getPayroll()
    this.getSched()
    this.checkHoliday()
  }
})
</script>
