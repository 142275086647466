<template>
    <div class="dashboard-div">
        <div class="attendance-stats-div">
                <div id="attendance-txt"><p>ATTENDANCE</p></div>
                <div id="date-div">
                    <p id="date-txt">DATE</p>
                    <p id="exact-date">{{ dateToday }}</p>
                </div>
            <div class="attendance-content">
                <p class="attendance-count">{{ attendance+ "/"+ totalEmployees.length }}</p>
            </div>
            <div class="attendance-absents">
                <p>ABSENTIES: <span class="absents-count">{{ absent }}</span></p>
            </div>
        </div>
        <div class="recent-count-div">
            <div id="recent-txt"><p>RECENT ATTENDANCE</p></div>
            <ul class="recent-list">
              <!--loading-->
              <div id="loading" v-if="loading">
                <font-awesome-icon icon="spinner"/>
                <p>Loading</p>
              </div>
              <!--noData-->
              <div id="list-noData" v-else-if="noData">
                <font-awesome-icon icon="inbox"/>
                <p>No Data</p>
              </div>
              <!--withData-->
              <li v-for="data in computedAttendace" :key="data.id" v-else>
                <p class="name-p">{{ data.firstName + ' ' + data.lastName }}</p>
                <p class="time-p" v-if="data.timeIn">{{ data.timeIn }}</p>
                <p class="time-p" v-else-if="data.timeOut">{{ data.timeOut }}</p>
                <p class="time-p" v-else>0:00</p>
              </li>
            </ul>
            <PaginationComponent
              :totalPages="totalAttendancePage"
              :perPage="5"
              :currentPage="currentAttendance"
              @onPageChange="onAttendanceChange"
            />
        </div>
        <div class="payroll-count-div">
            <div id="pay-txt"><p>PAYROLL</p></div>
            <div class="pay-list" v-if="loading">
              <div id="name-div">
                <p class="name-title">NAME</p>
                <!--loading-->
                <div id="loading">
                  <font-awesome-icon icon="spinner"/>
                  <p>Loading</p>
                </div>
              </div>
              <div id="salary-div">
                <p class="salary-title">SALARY</p>
                <!--loading-->
                <div id="loading">
                  <font-awesome-icon icon="spinner"/>
                  <p>Loading</p>
                </div>
              </div>
            </div>
            <div class="pay-list" v-else-if="noData2">
              <div id="name-div">
                <p class="name-title">NAME</p>
                <!--noData-->
                <div class="list-noData">
                  <font-awesome-icon icon="inbox"/>
                  <p>No Data</p>
                </div>
              </div>
              <div id="salary-div">
                <p class="salary-title">SALARY</p>
                <!--noData-->
                <div class="list-noData">
                  <font-awesome-icon icon="inbox"/>
                  <p>No Data</p>
                </div>
              </div>
            </div>
            <div class="pay-list" v-else>
                <div id="name-div">
                    <p class="name-title">NAME</p>
                    <!--withData-->
                    <ul class="employeeName" v-for="data in payrollList" :key="data.id">
                    <li @click="handleRedirect(data.id)">
                      {{ data.firstName + ' ' + data?.middleName + ' ' + data.lastName }}
                    </li>
                    </ul>
                </div>
                <div id="salary-div">
                    <p class="salary-title">SALARY</p>
                    <!--withData-->
                    <ul class="employeeName" v-for="data in payrollList" :key="data.id">
                      <li>
                        {{ data.grossEarnings + ' Php' }}
                      </li>
                    </ul>
                </div>
            </div>
            <PaginationComponent
              :totalPages="totalPayrollPage"
              :perPage="5"
              :currentPage="currentPayroll"
              @onPageChange="onPayrollChange"
            />
        </div>
    </div>
</template>

<script>
import axios from '@/api/axios'
import PaginationComponent from '@/components/PaginationComponent.vue'

import { mapState } from 'vuex'

export default (await import('vue')).defineComponent({
  name: 'mainComponent',
  data () {
    return {
      attendance: 0,
      totalEmployees: [],
      recentAttendance: [],
      payrollList: [],
      loading: true,
      loading2: true,
      noData2: false,
      noData: false,
      absent: 0,
      currentAttendance: 1,
      currentPayroll: 1,
      totalAttendance: 0,
      totalPayroll: 0,
      itemsPerPage: 5
    }
  },
  components: {
    PaginationComponent
  },
  computed: {
    dateToday () {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Manila' // Set the time zone to Philippine time
      }

      const date = new Date()
      const formattedDate = date.toLocaleDateString('en-PH', options)
      return formattedDate
    },
    ...mapState(['userData']),
    computedAttendace () {
      const startIndex = (this.currentAttendance - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      return this.recentAttendance.slice(startIndex, endIndex)
    },
    computedPayroll () {
      const startIndex = (this.currentPayroll - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      return this.payrollList.slice(startIndex, endIndex)
    },
    totalPayrollPage () {
      return Math.ceil(this.totalPayroll / this.itemsPerPage)
    },
    totalAttendancePage () {
      return Math.ceil(this.totalAttendance / this.itemsPerPage)
    }
  },
  methods: {
    handleRedirect (id) {
      this.$router.push(`payroll/user-history/${id}`)
    },
    onAttendanceChange (page) {
      this.currentAttendance = page
    },
    onPayrollChange (page) {
      this.currentPayroll = page
    },
    async attendanceToday () {
      const cookieValue = sessionStorage.getItem('token')

      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Manila'
      }
      const date = new Date()
      const formattedDate = date.toLocaleDateString('en-PH', options)

      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      try {
        const employees = await axios.get('/auth/creds', config)
        const data = await axios.get('/Attendance/records', config)

        this.totalEmployees = employees.data
        this.recentAttendance = []
        data.data.forEach((record) => {
          const recDate = new Date(record.date)
          const formattedRecDate = recDate.toLocaleDateString('en-PH', options)
          if (formattedRecDate === formattedDate) {
            this.recentAttendance.push(record)
          }
        })

        this.recentAttendance.sort((a, b) => {
          const dateA = a.timeIn || a.timeOut
          const dateB = b.timeIn || b.timeOut
          const parsedDateA = new Date(`${formattedDate} ${dateA}`)
          const parsedDateB = new Date(`${formattedDate} ${dateB}`)

          return parsedDateB - parsedDateA
        })
        this.totalAttendance = this.recentAttendance.length
        if (this.recentAttendance.length < this.totalEmployees.length) {
          if (this.attendance.length === 0) {
            this.attendance = this.recentAttendance.length
          }
          this.absent = this.recentAttendance.filter(a => a.type === 'absent').length
        } else {
          this.absent = 0
          this.attendance = this.totalEmployees.length
        }
        if (this.recentAttendance.length > 0) {
          this.noData = false
        } else {
          this.noData = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    async PayrollHistory () {
      const cookieValue = sessionStorage.getItem('token')
      const config = {
        headers: {
          Authorization: `${cookieValue}`
        }
      }
      try {
        const payrollHistory = await axios.get('payroll/payroll-history', config)
        if (payrollHistory.data.length > 0) {
          this.payrollList = payrollHistory.data
          this.totalPayroll = this.payrollList.length
          this.noData2 = false
        } else {
          this.noData2 = true
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  created () {
    // Fetch initial data when the component is created
    this.attendanceToday()
    this.PayrollHistory()
    setTimeout(() => {
      this.loading = false
      this.loading2 = false
    }, 3000)

    // Interval for fetching data.
    setInterval(this.attendanceToday, 20000)
  }
})
</script>
