<template>
    <div class="attendance-report-div">
        <div class="attRep-nav-div">
            <p class="generate-text">Generate Report</p>
            <div class="typeOf-display">
              <label for="displayType">Display:</label>
                <select name="displayType" id="graphs" @change="updateType">
                  <option value="graph" selected>graph</option>
                  <option value="list">list</option>
                </select>
            </div>
            <div class="filter-div">
              <div class="typeOf-export active">
                <label for="graphs" v-show="isGraph">Filter:</label>
                <select name="graphs" id="graphs" v-model="type" v-show="isGraph">
                  <option value="bar" selected>Bar Chart</option>
                  <option value="line">Line Chart</option>
                </select>
              </div>
              <div class="filter-options" v-show="isList">
                <div class="first-container">
                  <div class="filter-option2">
                    <label for="calendar">Date: </label>
                    <input
                    id="calendar"
                    name="calendar"
                    type="date"
                    v-model="date">
                  </div>
                </div>
                <div class="second-container">
                  <div class="search-div">
                    <input
                    type="text"
                    placeholder="search"
                    name="search"
                    id="search"
                    v-model="searchQuery"
                    autocomplete="off">
                    <font-awesome-icon icon="magnifying-glass" class="searchBtn" @click="searchBtn"/>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="attRep-content-div">
            <h2>Performance Report</h2>
            <div class="chart-div" v-show="isGraph">
                <canvas id="myChart"></canvas>
            </div>
            <div class="list-div" v-show="isList">
              <div class="attendance-list">
                <table class="attendance-table">
                  <tr id="title-row">
                    <th>EMPLOYEE NAME</th>
                    <th>DATE</th>
                    <th>TIME-IN</th>
                    <th>TIME-OUT</th>
                  </tr>
                  <!--loading-->
                  <tr id="details-no-data" v-if="loading">
                    <td colspan="5">
                      <font-awesome-icon icon="spinner"/>
                      Loading...
                    </td>
                  </tr>
                  <!--without data-->
                  <tr id="details-no-data" v-else-if="noData">
                    <td colspan="4">
                      <font-awesome-icon icon="inbox"/>
                      No Data
                    </td>
                  </tr>
                  <!--with data-->
                  <tr id="details-row" v-else v-for="attendance in computedAttendance" :key="attendance.Id" :class="getTypeClass(attendance.type)">
                    <td id="employeeName-row">
                      <p>{{ attendance.firstName + ' ' + attendance.lastName }}</p>
                    </td>
                    <td id="date-row">
                      <p>{{ attendance.date }}</p>
                    </td>
                    <td id="timeIn-row">
                      <p>{{ attendance.timeIn || '--'}}</p>
                    </td>
                    <td id="timeOut-row">
                      <p>{{ attendance.timeOut || '--'}}</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="pagination">
                <PaginationComponent
                  :totalPages="totalPages"
                  :perPage="5"
                  :currentPage="currentPage"
                  @pagechanged="onPageChange"
                  />
              </div>
            </div>
        </div>
        <div class="attRep-footer-div">
            <button class="exportBtn" @click="chartToXlsx">Export .xlsx</button>
        </div>
    </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue'
import axios from '@/api/axios'
import Chart from 'chart.js/auto'
import { writeFile, utils } from 'xlsx'

export default (await import('vue')).defineComponent({
  name: 'AttendanceModule',
  data () {
    return {
      type: 'line',
      isGraph: true,
      isList: false,
      chartInstance: null,
      currentPage: 1,
      totalItems: 0, // Total number of items
      itemsPerPage: 5,
      attendance: [],
      loading: true,
      noData: false,
      withData: false,
      searchQuery: '',
      date: ''
    }
  },
  components: {
    PaginationComponent
  },
  mounted () {
    this.attendanceData()
    this.initializeChart()
  },
  watch: {
    type (newType) {
      this.initializeChart()
    },
    date: 'attendanceData'
  },
  computed: {
    computedAttendance () {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage

      // Filter the data based on the type property
      const filteredAttendance = this.attendance.filter(entry => {
        const type = entry.type.toLowerCase() // Convert to lowercase for case-insensitive comparison
        return type === 'present' || type === 'late' || type === 'absent'
      })

      // Return a slice of the filtered data for the current page
      this.totalItems = filteredAttendance.length
      return filteredAttendance.slice(startIndex, endIndex)
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage) // Calculate total pages
    }
  },
  methods: {
    getTypeClass (type) {
    // Define your color coding logic based on the 'type' value
      if (type === 'present') {
        return 'present' // Replace 'present-class' with your actual class name
      } else if (type === 'absent') {
        return 'absent' // Replace 'absent-class' with your actual class name
      } else if (type.includes('late')) {
        return 'late' // Add more conditions as needed
      } else if (type === 'out') {
        return 'out'
      } else if (type === 'break') {
        return 'break'
      }
    },
    searchBtn (e) {
      e.preventDefault()
      this.withData = false
      this.loading = true
      this.attendance = []

      setTimeout(() => {
        this.attendanceData()
        this.loading = false
      })
    },
    onPageChange (page) {
      this.currentPage = page
    },
    updateType (e) {
      const target = e.target.value
      const typeOfExport = document.querySelector('.typeOf-export')
      const filter = document.querySelector('.filter-options')

      if (target === 'graph') {
        this.isList = false
        filter.classList.remove('active')
        this.isGraph = true
        typeOfExport.classList.add('active')
      } else {
        this.isGraph = false
        typeOfExport.classList.remove('active')
        this.isList = true
        this.loading = false
        setTimeout(() => {
          this.attendanceData()
          this.withData = true
        }, 2000)
        filter.classList.add('active')
      }
    },
    async attendanceData () {
      try {
        const search = this.searchQuery
        const date = this.date

        const apiUrl = `/Attendance/records?search=${search}&filter=${date}`
        const cookieValue = sessionStorage.getItem('token')
        const config = {
          headers: {
            Authorization: `${cookieValue}`
          }
        }
        const data = await axios.get(apiUrl, config)

        if (data.status === 200) {
          this.attendance = data.data
          this.$router.push(`/admin/attendance-report?search=${search}&date=${date}`)
        }

        if (this.attendance.length === 0) {
          this.noData = true
          this.withData = false
        } else {
          this.noData = false
          this.withData = true
        }
      } catch (error) {
        console.error('Error fetching employee data:', error)
      }
    },
    async initializeChart () {
      const ctx = document.getElementById('myChart')
      let attendanceData
      try {
        const cookieValue = sessionStorage.getItem('token')
        const config = {
          headers: {
            Authorization: `${cookieValue}`
          }
        }
        const attendance = await axios.get('/Attendance/records', config)
        if (attendance.status === 200) {
          attendanceData = attendance.data
        }
      } catch (e) {
        console.log(e)
      }
      const currentMonthAttendance = attendanceData.filter(record => {
        const recordDate = new Date(record.date)
        const currentMonth = new Date().getMonth()
        return recordDate.getMonth() === currentMonth
      })
      const numberOfDays = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate()
      const labels = Array.from({ length: numberOfDays }, (_, i) => i + 1)
      const presentData = labels.map(day =>
        currentMonthAttendance.filter(
          record =>
            new Date(record.date).getDate() === day &&
            record.type === 'present'
        ).length || 0
      )
      const lateData = labels.map(day =>
        currentMonthAttendance.some(
          record =>
            new Date(record.date).getMonth() === currentMonthAttendance &&
      new Date(record.date).getDate() === day &&
      record.type === 'late'
        ).length || 0
      )
      const absentData = labels.map(day =>
        currentMonthAttendance.some(
          record =>
            new Date(record.date).getMonth() === currentMonthAttendance &&
      new Date(record.date).getDate() === day &&
      record.type === 'absent'
        ).length || 0
      )
      this.chartInstance = new Chart(ctx, {
        type: this.type,
        data: {
          labels: labels,
          datasets: [{
            label: 'Present',
            data: presentData,
            borderColor: '#09ee1c',
            backgroundColor: '#09ee1c',
            borderWidth: 1
          },
          {
            label: 'Late',
            data: lateData,
            borderColor: '#f5f105',
            backgroundColor: '#f5f10',
            borderWidth: 1
          },
          {
            label: 'Absent',
            data: absentData,
            borderColor: '#36A2EB',
            backgroundColor: '#EB7474',
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })

      // Assign the chart instance to the canvas element's property
      ctx.__vue_chart_instance__ = this.chartInstance
    },

    chartToXlsx () {
      if (this.isGraph) {
        const monthName = new Date().toLocaleString('default', { month: 'long' })
        // Create an array for Excel data
        const data = []

        // Add the header row with labels
        const headerRow = [''] // Initial empty cell
        this.chartInstance.data.labels.forEach((label) => {
          headerRow.push(label) // Add label to the same row
        })
        data.push(headerRow)

        // Combine dataset label and data in a single row
        this.chartInstance.data.datasets.forEach((dataset) => {
          const rowData = [dataset.label, ...dataset.data]
          data.push(rowData)
        })

        // Create a new workbook
        const wb = utils.book_new()

        // Create a worksheet and add the data
        const ws = utils.aoa_to_sheet(data)
        utils.book_append_sheet(wb, ws, 'Sheet1')

        // Generate and download the Excel file
        writeFile(wb, `${monthName}-stats.xlsx`)
      } else if (this.isList) {
        const monthName = new Date().toLocaleString('default', { month: 'long' })
        // Create an array for Excel data
        const data = []

        // Add the header row with labels
        const headerRow = ['EMPLOYEE NAME', 'DATE', 'TIME-IN', 'TIME-OUT']
        data.push(headerRow)

        // Add the attendance data to the array
        this.attendance.forEach((item) => {
          const rowData = [`${item.firstName} ${item.lastName}`, item.date, item.timeIn, item.timeOut]
          data.push(rowData)
        })
        // Create a new workbook
        const wb = utils.book_new()

        // Create a worksheet and add the data
        const ws = utils.aoa_to_sheet(data)
        utils.book_append_sheet(wb, ws, 'Sheet1')

        // Generate and download the Excel file
        writeFile(wb, `${monthName}-attendance.xlsx`)
      }
    }

  },
  created () {
    this.attendanceData()
  }
})
</script>
